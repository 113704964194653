(function () {
    'use strict';

    /**
     * @ngdoc component
     * @name app.component:enumerado
     *
     * @param {String} value Valor del enumerado
     * @param {String} base Clave base para el fichero de mensajes
     */
    angular
        .module('app')
        .component('enumerado', {
            template: '<span ng-if="ctrl.value">{{ctrl.base + "." + ctrl.value | translate}}</span>',
            controllerAs: 'ctrl',
            bindings: {
                value: '@',
                base: '@'
            }
        });

})();
