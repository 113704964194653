(function () {
    'use strict';

    /**
     * @ngdoc component
     * @name app.component:booleano
     * @description
     * Componente para mostrar el texto Sí/No
     *
     * @param {String} value Valor booleano (true/false -> Sí/No).
     *
     */
    angular
        .module('app')
        .component('booleano', {
            template: '{{"enum.boolean." + ctrl.value | translate}}',
            controllerAs: 'ctrl',
            bindings: {
                value: '@'
            }
        });

})();
