(function () {
    'use strict';

    /**
     * @ngdoc service
     * @name app.service:EstaticaModal
     *
     * @requires app.service:ModalService
     *
     */
    angular.module('app')
        .service('EstaticaModal', EstaticaModal);

    // TODO: Mejora: Se podría refactorizar haciendo que la plantilla fuese el header y footer del modal y el contenido un include de la página en concreto.
    // TODO: Sólo acepta páginas estáticas

    /* @ngInject */
    function EstaticaModal(ModalService, Language) {
        return {
            open: open
        };

        /**
         * @param id nombre del archivo
         */
        function open(id) {
            ModalService.open({
                    templateUrl: function () {
                        return 'estaticas/' + id + '/' + id + '.' + Language.getCurrent() + '.html';
                    },
                    /* @ngInject */
                    controller: function Controller(ModalService, title) {
                        var vm = this;

                        vm.title = title;
                        vm.close = function () {
                            ModalService.dismiss();
                        };
                    },
                    controllerAs: 'ctrl',
                    resolve: {
                        title: function () {
                            return 'estatica.' + id + ".title"
                        }
                    }
                }
            );
        }
    }

})();
