(function (angular) {
    'use strict';

    angular
        .module('app')
        .component('socialMenu', {
            templateUrl: 'app/components/aspg/socialmenu/social-menu.html',
            controllerAs: 'ctrl',
            controller: Controller
        });

    function Controller($window) {
        var vm = this;

        vm.abrir = function (url) {
            $window.open(
                url,
                '_blank'
            );
        }

    }

})(angular);
