(function () {
    'use strict';

    angular
        .module('app')
        .component('usuariosListAdmin', {
            templateUrl: 'app/components/aspg/usuarios/admin/usuarios/usuarios-list.admin.html',
            controller: Controller,
            controllerAs: 'ctrl'
        });

    /* @ngInject */
    function Controller($stateParams, NgTableParams, NgTableHelper,
                        ModalService, UsuarioAspg, NG_TABLE_DEFAULT_PARAMS, Descargas) {
        var vm = this;

        vm.item = UsuarioAspg;
        vm.pagina = $stateParams.page ? $stateParams.page : 1;
        vm.filter = {
            key: null,
            eliminado: false
        };
        vm.tableParams = new NgTableParams({
            count: NG_TABLE_DEFAULT_PARAMS.size,
            page: vm.pagina,
            filter: vm.filter,
            sorting: {login: 'asc'}
        }, NgTableHelper.settings(vm));

        // Eliminar
        vm.showRemoveConfirmation = function (usuario, borradoFisico) {
            var titulo = 'usuarios.admin.usuarios.deshabilitar.title';
            var body = 'usuarios.admin.usuarios.deshabilitar.confirm';

            if (borradoFisico){
                titulo = 'usuarios.admin.usuarios.delete.title';
                body = 'usuarios.admin.usuarios.delete.confirm';
            }
            ModalService.open({
                    templateUrl: 'app/components/form/delete/entity.delete.modal.html',
                    controller: 'EntityDeleteModalController',
                    controllerAs: 'ctrl',
                    resolve: {
                        /* @ngInject */
                        item: function (UsuarioAspg) {
                            return new UsuarioAspg(usuario);
                        },
                        params: function () {
                            return {
                                title: titulo,
                                body: body,
                                property: 'login',
                                borradoFisico: borradoFisico
                            };
                        }
                    }
                }
            ).result.then(function () {
                vm.tableParams.reload();
            });
        };

        // Recuperar
        vm.showRecoverConfirmation = function (usuario) {
            ModalService.open({
                    templateUrl: 'app/components/form/confirm/confirm.modal.html',
                    controller: 'ConfirmModalController',
                    controllerAs: 'ctrl',
                    resolve: {
                        item: function () {
                            return usuario;
                        },
                        params: function () {
                            return {
                                title: 'usuarios.admin.usuarios.recover.title',
                                body: 'usuarios.admin.usuarios.recover.confirm',
                                property: 'login'
                            };
                        },
                        /* @ngInject */
                        tipo: function (UsuarioAspg) {
                            return UsuarioAspg;
                        },
                        funcion: function () {
                            return "recover";
                        },
                        parametros: function () {
                            return {id: usuario.id};
                        }
                    }
                }
            ).result.then(function () {
                vm.tableParams.reload();
            });
        };

        // Generar lista de usuarios
        vm.generarExcel = function () {
            Descargas.descargar('api/usuario-aspg/generar-excel');
        };

    }

})();
