(function () {
    'use strict';

    /**
     * @ngdoc component
     * @name app.component:contactoMap
     * @description
     * Mapa con un punto de contacto.
     *
     * Pensado únicamente a modo contacto, no para añadir más marcadores.
     *
     * Pasos:
     * - Obtener una clave.
     * - Ponerla en `apis.googleMaps`, en el archivo de Gulp `config.js`.
     *
     * __Referencia:__ https://developers.google.com/maps/documentation/javascript/
     * https://developers.google.com/maps/documentation/javascript/3.exp/reference
     *
     * @param {Number} lat Latitud del punto.
     * @param {Number} lng Longitud del punto.
     * @param {Number} [zoom=15] Zoom por defecto al cargar el mapa.
     * @param {String} icono Imagen del marcador. Si no se indica ninguna, el icono es de Google Maps.
     *
     */
    angular
        .module('app')
        .component('contactoMapa', {
            templateUrl: 'app/components/contactomapa/contacto-mapa.html',
            controllerAs: 'ctrl',
            controller: Controller,
            bindings: {
                lat: '<',
                lng: '<',
                zoom: '<',
                titulo: '@',
                icono: '<'
            }
        });

    /* @ngInject */
    function Controller($sce, $ocLazyLoad, APIS) {
        var vm = this;

        vm.clave = APIS.googleMaps;

        $ocLazyLoad.load('js!https://maps.googleapis.com/maps/api/js?key=' + vm.clave).then(function () {
            vm.initMap();
        });

        vm.initMap = function () {
            var posicion = {lat: vm.lat, lng: vm.lng};

            // Create a map object and specify the DOM element for display.
            var map = new google.maps.Map(document.getElementById('mapa'), {
                center: posicion,
                zoom: vm.zoom || 15,
                fullscreenControl: false
            });

            // Create a marker and set its position.
            new google.maps.Marker({
                map: map,
                position: posicion,
                icon: vm.icono,
                title: vm.titulo
            });
        }
    }

})();
