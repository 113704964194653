(function () {
    'use strict';

    angular
        .module('app')
        .component('temaListPublic', {
            templateUrl: 'app/components/auladigital/public/tema-list.public.html',
            controller: Controller,
            controllerAs: 'ctrl',
            bindings: {
                temas: '<'
            }
        });

    /* @ngInject */
    function Controller(AulaDigitalUtil) {
        var vm = this;

        vm.urlBaseArchivo = AulaDigitalUtil.getRutaRecursosBase();
    }

})();
