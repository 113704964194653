(function () {
    'use strict';

    /**
     * @ngdoc component
     * @name app.component:buttons
     * @description
     * Componente que añade los botones de guardar/editar y de cancelar en los formularios.
     *
     * @param {Boolean} canSave Si es false, se deshabilita el botón de guardar
     * @param {String} mode Modos permitidos: `create`, `edit`
     * @param {Function} validar Función de validación al pulsar sobre el botón de submit. Opcional
     *
     */
    angular
        .module('app')
        .component('buttons', {
            templateUrl: 'app/components/form/buttons/buttons-form.template.html',
            controller: Controller,
            controllerAs: 'ctrl',
            bindings: {
                canSave: '<',
                mode: '<',
                validar: '<'
            }
        });

    function Controller($rootScope, ModalService) {
        var vm = this;

        // Si hay una ventana modal abierta, se cierra
        vm.back = function () {
            if (ModalService.isOpen()) {
                ModalService.dismiss();
            } else {
                $rootScope.back();
            }
        };

    }

})();
