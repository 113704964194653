(function () {
    'use strict';

    /**
     * @ngdoc service
     * @name app.service:FormUtils
     * @description
     * Operaciones útiles para tratar con formularios.
     *
     */
    angular.module('app')
        .factory('FormUtils', Service);

    /* @ngInject */
    function Service() {
        return {
            /**
             * @ngdoc function
             * @name validarTabs
             * @methodOf app.service:FormUtils
             * @description
             * Método para validar los formularios que se encuentran dentro de pestañas (internacionalizados).
             * Comprueba si existen errores de validación y devuelve el índice de la pestaña que contiene al menos uno de estos errores.
             *
             * __Nota:__ Para que funcione correctamente, es necesario que los inputs tengan como ID un nombre y finalicen en `_idioma`.
             * Ej: `"titulo_{{idioma}}"` (que generaría el ID: _titulo_es_).
             *
             * @param {Object} form Formulario que contiene los errores (`$error`).
             * @param {Number} activa Pestaña activa actualmente.
             * @param {Array} idiomas Lista de idiomas de las pestañas.
             * @returns {Number} Índice de la pestaña que contiene al menos un error.
             * En caso de que no haya errores, se devuelve la misma.
             */
            validarTabs: validarTabs
        };

        function validarTabs(form, activa, idiomas) {
            if (form.$error) {
                var keys = Object.keys(form.$error);
                var i = 0;
                while (i < keys.length) {
                    var key = keys[i];

                    var inputErrorId = form.$error[key][0].$$element[0].id;

                    var activarIdioma = inputErrorId.substr(-2);
                    if (activarIdioma && idiomas.indexOf(activarIdioma) >= 0) {
                        activa = idiomas.indexOf(activarIdioma);
                        i = keys.length;
                    }
                    i++;
                }
            }
            return activa;
        }
    }
})();
