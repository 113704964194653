(function () {
    'use strict';

    angular
        .module('app')
        .component('adminListAdmin', {
            templateUrl: 'app/admin/user/admin/admin-list.admin.html',
            controller: Controller,
            controllerAs: 'ctrl'
        });

    /* @ngInject */
    function Controller($stateParams, NG_TABLE_DEFAULT_PARAMS, NgTableParams, NgTableHelper,
                        ModalService, Administrador) {
        var vm = this;

        vm.item = Administrador;
        vm.pagina = $stateParams.page ? $stateParams.page : 1;
        vm.filter = {};
        vm.tableParams = new NgTableParams({
            count: NG_TABLE_DEFAULT_PARAMS.size,
            page: vm.pagina,
            filter: vm.filter,
            sorting: {login: 'asc'}
        }, NgTableHelper.settings(vm));

        // Eliminar
        vm.showRemoveConfirmation = function (user) {
            ModalService.open({
                templateUrl: 'app/components/form/delete/entity.delete.modal.html',
                controller: 'EntityDeleteModalController',
                controllerAs: 'ctrl',
                resolve: {
                    item: function () {
                        return Administrador.getUser({login: user.login}).$promise;
                    },
                    params: function () {
                        return {
                            title: 'admin.list-users.delete.title',
                            body: 'admin.list-users.delete.confirm',
                            property: 'login'
                        };
                    }
                }
            }).result.then(function () {
                vm.tableParams.reload();
            });
        };
    }

})();
