(function () {
    'use strict';

    /**
     * @ngdoc component
     * @name app.component:selectForm
     * @description
     * Componente para incluir un select de elementos.
     *
     * __Nota sobre el formateado:__ Se acepta dar un formato especial a los selects.
     * Para esto habrá que pasar listas de elementos, separadas por comas, en display y base, además del campo format.
     * Este campo tendrá un _ en cada variable que se quiera rellenar (uno por cada display).
     *
     * Ejemplo: `display="nombre,id" format="_ (_)"`, producirá una lista de elementos de la siguiente forma: `Cervantes (1)`.
     *
     * @param {Object} model Objeto en el que se almacenará el resultado
     * @param {Array} options Lista de opciones
     * @param {String} id ID del input
     * @param {String} label Etiqueta
     * @param {String} tooltip Etiqueta del tooltip
     * @param {String} [empty=""] Etiqueta cuando no hay elementos seleccionados
     * @param {String} [display="nombre"] Propiedad que se mostrará al seleccionar. Permite recibir una lista de propiedades separados por comas
     * @param {String} [format="_"] Formato que tendrá el select. Se pondrá un _ por cada variable indicada en el display.
     * @param {Boolean} [required=false] Campo requerido
     * @param {Boolean} [disabled=false] Select deshabilitado
     * @param {String} base Texto base del enumerado. Con este String se sabe si se está mostrando un enumerado o un objeto. Ej: `enum.idiomas.`
     * @param {Function} change Función que se llama al seleccionar un elemento
     *
     * @requires app.component:labelForm
     */
    angular
        .module('app')
        .component('selectForm', {
            templateUrl: 'app/components/form/select/select-form.html',
            controllerAs: 'ctrl',
            bindings: {
                model: '=', // required
                options: '<', // required
                id: '@',
                label: '@',
                tooltip: '@',
                empty: '@',
                display: '@',
                format: '@',
                required: '<',
                disabled: '<',
                base: '@',
                change: '='
            },
            /* @ngInject */
            controller: function ($translate) {
                var vm = this;

                // Valores por defecto
                vm.display = vm.display || "nombre";
                vm.format = vm.format || "_";
                vm.base = vm.base || "";

                var list = vm.display.split(",");

                var base = vm.base.split(",");

                // FIXME: Esta operación se llama cada vez que se interactúa con un select para recalcular el valor de TODOS los options de todos los selects.
                vm.formatElement = function (element) {
                    var formated = vm.format;
                    angular.forEach(list, function (elementDisplay, index) {
                        var baseText = base[index] ? base[index].trim() : "";
                        var translated = $translate.instant(baseText + element[elementDisplay.trim()]);
                        formated = formated.replace("_", translated);
                    });
                    return formated;
                }
            }
        });

})();
