(function () {
    'use strict';

    // TODO: Añadir un botón para crear un nuevo elemento del tesauro para cuando es una lista simple de entidades: "Crear y asignar"
    // TODO: Poner ejemplo de acordeon

    /**
     * @ngdoc component
     * @name app.component:listForm
     * @description
     * Este componente permite la gestión (añadir y eliminar) elementos de una lista de manera dinámica.
     *
     * __Nota:__ Dentro de la plantilla (templateUrl) hay que utilizar la variable `element`, ya que será así como se llame a cada objeto sobre el que se itere en el array.
     *
     * Ejemplo: `original-form.admin.html`.
     *
     * @param {String} label Texto para la etiqueta
     * @param {String} tooltip Texto para el tooltip
     * @param {Array} list Lista de elementos que se modificará
     * @param {Object} resolve Información del controller desde donde es invocado el componente.
     * Ej: `{agenteService: ctrl.agenteService, openAgenteModal: ctrl.openAgenteModal}`
     * @param {String} templateUrl ID de la plantilla HTML que se mostrará, con los diferentes inputs
     * @param {Boolean} [required=false] Si es true, siempre habrá al menos un elemento en la lista
     * @param {Boolean} [acordeon=false] Indica si cada elemento se incluye en un acordeón. Se usa para ajustar el botón de eliminar
     * @param {Boolean} [noLabels=false] Indica que las filas no incluyen etiquetas, por lo que hay que ajustar la posición del botón de eliminar
     *
     * @requires app.component:labelForm
     *
     */
    angular
        .module('app')
        .component('listForm', {
            templateUrl: 'app/components/form/listform/list-form.html',
            controller: Controller,
            controllerAs: 'ctrl',
            bindings: {
                label: '@',
                tooltip: '@',
                list: '=',
                resolve: '<',
                templateUrl: '@',
                required: '<',
                acordeon: '<',
                noLabels: '<'
            }
        });

    /* @ngInject */
    function Controller() {
        var vm = this;

        function initList() {
            if (!vm.list) {
                vm.list = [];
                if (vm.required) {
                    vm.list.push({});
                }
            } else if (!vm.list.length && vm.required) {
                vm.list.push({});
            }
        }

        vm.addElement = function () {
            vm.list.push({desplegado: true});
        };

        vm.removeElement = function (element) {
            vm.list.splice(vm.list.indexOf(element), 1);
            initList();
        };

        initList();
    }

})();
