(function () {
    'use strict';

    /**
     * @ngdoc component
     * @name app.component:buttonTop
     * @description
     * Componente que añade un botón flotante para ir arriba.
     *
     */
    angular
        .module('app')
        .component('buttonTop', {
            template: '<button id="button-top" ng-click="ctrl.topFunction()"><span class="glyphicon glyphicon-arrow-up"></span></button>',
            controller: Controller,
            controllerAs: 'ctrl'
        });

    /* @ngInject */
    function Controller($window) {
        var vm = this;

        // Botón ir arriba
        $window.onscroll = function () {
            scrollFunction();
        };

        function scrollFunction() {
            if (document.getElementById("button-top") != undefined) {
                if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
                    document.getElementById("button-top").style.display = "block";
                } else {
                    document.getElementById("button-top").style.display = "none";
                }
            }
        }

        vm.topFunction = function () {
            $("html, body").animate({scrollTop: "0px"}, 1000);
        };
    }

})();
