(function () {
    'use strict';

    /**
     * @ngdoc filter
     * @name app.filter:fileSize
     * @description
     * Devuelve el tamaño de un archivo redondeando a su unidad más próxima.
     * Si la unidad es de MB o superior, se mostrará con dos decimales.
     *
     * Ej: '156663 Bytes' = '153 KB'
     *
     * @returns {Number} Número de bytes de un archivo.
     */
    angular
        .module('app')
        .filter('fileSize', filter);

    function filter() {
        return calcular;

        function calcular(bytes) {
            var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
            if (bytes === 0) return '0 Byte';
            var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
            var value = bytes / Math.pow(1024, i);
            if (i > 1) {
                value = value.toFixed(2);
                if (value % 1 == 0) {
                    value = Math.round(value, 2);
                }
            } else {
                value = Math.round(value, 2);
            }

            return value + ' ' + sizes[i];
        }
    }
})();
