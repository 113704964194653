(function () {
    'use strict';

    angular
        .module('app')
        .config(stateConfig);

    /* @ngInject */
    function stateConfig($stateProvider) {
        $stateProvider.state('site', {
            abstract: true,
            data: {
                authorities: []
            },
            resolve: {
                /* @ngInject */
                authorize: function (Auth) {
                    return Auth.authorize();
                },
                /* @ngInject */
                isFilemanager: function ($rootScope) {
                    $rootScope.isFilemanager = false;
                },
                /* @ngInject */
                translatePartialLoader: function ($translatePartialLoader) {
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('componentes');
                    $translatePartialLoader.addPart('enum');
                    $translatePartialLoader.addPart('public');
                    // Necesario cargarlo para las entradas del menú personalizadas
                    $translatePartialLoader.addPart('componentes/estaticas');
                    // Necesario para la información del footer
                    $translatePartialLoader.addPart('componentes/contacto');
                }
            }
        });
    }
})();
