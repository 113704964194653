(function () {
    'use strict';

    angular
        .module('app')
        .config(stateConfig);

    /* @ngInject */
    function stateConfig($stateProvider, STATE_HELPER, MUSEO_FORM_ADMIN) {
        var config = MUSEO_FORM_ADMIN();
        var base = "admin/museos/museo";
        var params = {
            view: 'contentChildren',
            base: base,
            baseUrl: "/museo",
            translateBase: "museos.museo.admin.",
            templateList: '<museo-list-admin></museo-list-admin>',
            templateForm: config.component
        };

        var state = STATE_HELPER(params);
        // Estado Padre
        angular.merge(state.parent, {
            data: {
                authorities: ['ROLE_ADMIN'],
                hideBreadcrumb: true
            },
            views: {
                'content@': {
                    template: '<ng-include src="\'app/components/aspg/museos/admin/museos-breadcrumb.admin.html\'"></ng-include>' +
                    '<div ui-view="contentChildren"></div>'
                }
            },
            resolve: {
                /* @ngInject */
                breadcrumb: function ($breadcrumb) {
                    return $breadcrumb;
                },
                /* @ngInject */
                translatePartialLoader: function ($translatePartialLoader) {
                    $translatePartialLoader.addPart('componentes/museos');
                }
            }
        });
        // Listar
        angular.merge(state.list, {});
        // Crear
        angular.merge(state.create, {
            resolve: config.resolve
        });
        // Editar
        angular.merge(state.edit, {
            resolve: angular.merge(config.resolve, {
                /* @ngInject */
                item: function (Museo, $stateParams) {
                    return Museo.get({id: $stateParams.id}).$promise;
                }
            })
        });

        // Se definen los estados
        $stateProvider
            .state(params.base, state.parent)
            .state(state.list.name, state.list)
            .state(state.create.name, state.create)
            .state(state.edit.name, state.edit);
    }
})();
