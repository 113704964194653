(function () {
    'use strict';

    angular.module('app')
        .factory('Suscriptor', Service);

    /* @ngInject */
    function Service(ResourceHelper) {
        var entidad = "suscriptor";
        return ResourceHelper.getResource(entidad, {}, {
            enviarEmail: {
                method: 'POST',
                url: 'api/' + entidad + '/enviar-email'
            },
            cancelarSuscripcion: {
                method: 'GET',
                url: 'api/' + entidad + '/unsuscribe',
                params: {
                    key: null
                }
            }
        });
    }

})();
