(function () {
    'use strict';

    angular
        .module('app')
        .component('salaListPublic', {
            templateUrl: 'app/components/aspg/museos/public/salamuseo/sala-list.public.html',
            controllerAs: 'ctrl',
            bindings: {
                salas: '<'
            }
        });
})();
