(function () {
    'use strict';

    angular
        .module('app')
        /* @ngInject */
        .constant('RECURSO_EDUCATIVO_FORM_ADMIN', function (modal) {
            var resolve = "$resolve.";
            if (modal) {
                resolve = "ctrl.resolve."
            }
            return {
                component: '<recurso-educativo-form-admin ' +
                'item="' + resolve + 'item" ' +
                'previous-params="' + resolve + 'previousParams"' +
                'temas="' + resolve + 'temas" ' +
                '></recurso-educativo-form-admin>',
                resolve: {
                    /* @ngInject */
                    item: function (RecursoEducativo) {
                        return new RecursoEducativo();
                    },
                    /* @ngInject */
                    temas: function ($stateParams, Tema) {
                        return Tema.query({
                            padreId: $stateParams.materiaid,
                            sortProperty: 'orden'
                        }).$promise.then(function (data) {
                            return data.content;
                        });
                    }
                }
            }
        });
})();
