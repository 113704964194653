(function () {
    'use strict';

    angular
        .module('app')
        .component('actualidad', {
            templateUrl: 'app/components/aspg/actualidad/actualidad.html',
            controllerAs: 'ctrl',
            controller: Controller,
            bindings: {
                categoriaRssId: '<'
            }
        });

    /* @ngInject */
    function Controller(Rss) {
        var vm = this;

        vm.loading = true;

        Rss.query({categoriaId: vm.categoriaRssId}).$promise.then(function (data) {
            vm.loading = false;
            vm.entradas = data;
        });

    }

})();
