(function () {
    'use strict';

    /**
     * @ngdoc component
     * @name app.component:exploradorArchivos
     * @description
     * Componente que muestra un explorador de archivos.
     *
     * Se integra con CKEditor y permite seleccionar archivos.
     *
     * También permite edición de ciertos archivos y previsualización.
     *
     * Referencia: https://github.com/joni2back/angular-filemanager
     *
     * __Requiere__ AngularFilemanager y ocLazyLoad.
     */
    angular
        .module('app')
        .component('exploradorArchivos', {
            template: '<span oc-lazy-load="FileManagerApp">' +
            '<angular-filemanager></angular-filemanager>' +
            '</span>',
            controllerAs: 'ctrl',
            controller: Controller
        });

    /* @ngInject */
    function Controller($injector, $ocLazyLoad) {
        // Cargamos los archivos necesarios de angular-filemanager y CKEditor.
        $ocLazyLoad.load({
            name: 'FileManagerApp',
            files: [
                'assets/bower/angular-filemanager/angular-filemanager.min.js',
                'assets/bower/angular-filemanager/angular-filemanager.min.css',
                'assets/ckeditor/ckeditor.js',
                'assets/js/file-manager.service.js'
            ]
        }).then(function () {
            var FileManagerService = $injector.get('FileManagerService');

            // TODO: Se puede parametrizar la configuración para recibir información. Ejemplo: tipo de elementos que hay que mostrar
            FileManagerService.config();
        });

    }

})();
