(function () {
    'use strict';

    angular
        .module('app')
        .config(stateConfig);

    /* @ngInject */
    function stateConfig($stateProvider) {
        $stateProvider.state('admin/configuracion', {
            parent: 'admin',
            url: '/configuracion',
            redirectTo: 'admin/configuracion/edit',
            data: {
                pageTitle: 'configuracion.titulo'
            },
            ncyBreadcrumb: {
                label: 'configuracion.titulo'
            }
        }).state('admin/configuracion/edit', {
            parent: 'admin/configuracion',
            url: '/editar',
            views: {
                'content@': {
                    template: '<configuracion-form-admin configuraciones="$resolve.configuraciones"></configuracion-form-admin>'
                }
            },
            resolve: {
                /* @ngInject */
                translatePartialLoader: function ($translatePartialLoader) {
                    $translatePartialLoader.addPart('componentes/configuracion');
                },
                /* @ngInject */
                configuraciones: function (Configuracion) {
                    return Configuracion.query().$promise.then(function (data) {
                        return data.content;
                    });
                }
            },
            ncyBreadcrumb: {
                skip: true
            }
        });
    }
})();
