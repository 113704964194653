(function () {
    'use strict';

    /**
     * @ngdoc component
     * @name app.component:descargar
     * @description
     * Componente que permite la descarga de un archivo, mostrando un icono u otro en función de la extensión del mismo.
     *
     * @param {Object} archivo Nombre del archivo
     * @param {String} url URL del directorio donde se encuentra el archivo
     * @param {String} [texto=ctrl.archivo] Texto que se mostrará.
     * Si no se indica ninguno, se mostrará el nombre del archivo. Si se indica cadena vacía, no se mostrará ningún texto.
     * @param {String} [titulo='descargar.titulo'] Texto que se muestra al poner el ratón sobre el enlace
     * @param {Boolean} [mostrarBoton=false] Booleano para indicar si el elemento se muestra en formato botón o como un link
     *
     */
    angular.module('app')
        .component('descargar', {
            templateUrl: 'app/components/descargar/descargar.html',
            controllerAs: 'ctrl',
            controller: Controller,
            bindings: {
                archivo: '<',
                url: '@',
                texto: '@',
                titulo: '@',
                mostrarBoton: '<',
                size: '<'
            }
        });

    function Controller() {
        var vm = this;

        // Valores por defecto
        // Si se envía un string vacío, entonces no se mostrará texto.
        if (vm.texto === undefined) {
            vm.texto = vm.archivo;
        }
        vm.titulo = vm.titulo || "descargar.titulo";

        //

        vm.descargarArchivo = function () {
            window.open(vm.url + vm.archivo, '_blank');
        };

        vm.getClase = function () {
            var extension = vm.archivo.split('.').pop();
            switch (extension) {
                // Documentos
                case 'pdf':
                    return 'fa fa-file-pdf-o';
                case 'txt':
                case 'text':
                    return 'fa fa-file-text-o';
                case 'doc':
                case 'docx':
                    return 'fa fa-file-word-o';
                case 'xls':
                case 'xlsx':
                    return 'fa fa-file-excel-o';
                case 'ppt':
                case 'pptx':
                    return 'fa fa-file-powerpoint-o';
                case 'html':
                    return 'fa fa-file-code-o';
                // Vídeos
                case 'mp4':
                case 'webm':
                case 'avi':
                    return 'fa fa-file-video-o';
                // Audios
                case 'mp3':
                case 'ogg':
                    return 'fa fa-file-audio-o';
                // Imágenes
                case 'jpg':
                case 'jpeg':
                case 'png':
                case 'bmp':
                case 'gif':
                    return 'fa fa-file-image-o';
                // Otros
                case 'zip':
                    return 'fa fa-file-archive-o';
                default:
                    return 'fa fa-download';
            }
        }
    }

})();
