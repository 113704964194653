(function () {
    'use strict';

    /**
     * @ngdoc directive
     * @name app.directive:sameAs
     * @description
     * Directiva para comprobar que dos campos son iguales.
     *
     * Utilizada, por ejemplo, cuando hay que comprobar que le usuario introdujo la misma contraseña dos veces.
     *
     * @param {Object} sameAs Elemento con el que se va a comparar
     *
     */
    angular.module('app')
        .directive('sameAs', directive);

    function directive() {
        return {
            require: '?ngModel',
            restrict: 'A',
            link: function (scope, elem, attrs, ngModel) {
                ngModel.$parsers.unshift(validate);

                scope.$watch(attrs.sameAs, function () {
                    ngModel.$setViewValue(ngModel.$viewValue);
                });

                function validate(value) {
                    var isValid = scope.$eval(attrs.sameAs) === value;
                    ngModel.$setValidity('sameAs', isValid);
                    return isValid ? value : undefined;
                }
            }
        };
    }
})();
