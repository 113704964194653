(function () {
    'use strict';

    angular
        .module('app')
        .config(stateConfig);

    /* @ngInject */
    function stateConfig($stateProvider) {
        $stateProvider
            .state('admin/estaticas', {
                parent: 'admin',
                url: '/admin/estaticas/:nombre',
                data: {
                    authorities: ['ROLE_ADMIN'],
                    pageTitle: 'estaticas.titulo'
                },
                views: {
                    'content@': {
                        template: '<estatica-form item="$resolve.item" idiomas="$resolve.idiomas"></estatica-form>',
                        controllerAs: 'ctrl'
                    }
                },
                resolve: {
                    /* @ngInject */
                    translatePartialLoader: function ($translatePartialLoader) {
                        $translatePartialLoader.addPart('componentes/estaticas');
                    },
                    /* @ngInject */
                    item: function (Estatica, $stateParams) {
                        return Estatica.get({nombre: $stateParams.nombre}).$promise.then(function (data) {
                            return data;
                        });
                    },
                    /* @ngInject */
                    idiomas: function (Language) {
                        return Language.getList();
                    }
                },
                ncyBreadcrumb: {
                    label: 'estaticas.titulo'
                }
            });
    }
})();
