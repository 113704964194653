(function () {
    'use strict';

    angular.module('app')
        .config(stateConfig);

    /* @ngInject */
    function stateConfig($stateProvider) {
        $stateProvider.state('public', {
            abstract: true,
            parent: 'site',
            views: {
                'css@': {
                    templateUrl: 'app/public/public.css.html'
                },
                'navbar@': {
                    template: '<mi-menu menu="$ctrl.menu" nav-collapsed="$ctrl.navCollapsed"></mi-menu>',
                    /* @ngInject */
                    controller: function (menuData, $window) {
                        this.menu = menuData;
                        this.navCollapsed = $window.innerWidth < 992;
                    },
                    controllerAs: '$ctrl'
                },
                'footer@': {
                    templateUrl: 'app/layouts/footer/footer.html'
                }
            },
            resolve: {
                /* @ngInject */
                isAdmin: function ($rootScope) {
                    $rootScope.isAdmin = false;
                },
                /* @ngInject */
                menuData: function (Menu) {
                    return Menu.getMenu('menu-main');
                }
            }
        });
    }
})();
