(function () {
    'use strict';

    /**
     * @ngdoc component
     * @name app.component:fileUpload
     * @description
     * Componente para la subida de archivos. También permite la descarga de los mismos.
     *
     * Referencia: https://github.com/danialfarid/ng-file-upload
     *
     * @param {Object} model Objeto en el que se almacenará el resultado.
     * En `path` se almacenará el nombre del archivo, y en `archivoTemporal` irá el nombre del archivo .tmp
     * @param {String} id ID del input
     * @param {String} label Etiqueta.
     * @param {String} [accept=''] Extensiones que se aceptan. Si no se indica nada, no se establece ningún filtro.
     * Ej: `.pdf,.doc`, o `image/*`
     * @param {String} url URL de la carpeta en la que se encuentra el archivo. Si no se pasa, no se muestra el icono de descarga
     * @param {Number} size Número de bytes del archivo subido.
     * @param {String} [authorities=''] Lista de roles para los que será visible el botón de descarga. Si no se indica nada, no se establece ningún filtro.
     * Ej: `ROLE_ADMIN,ROLE_COLABORADOR`
     * @param {Function} onSelected Función llamada cuando se selecciona un archivo.
     * `vm.onSelected(vm.model.archivoTemporal, vm.model.path)`
     * @param {Boolean} [disableDrop=false] Booleano para indicar si se permite arrastrar un archivo desde fuera del navegador o no.
     * @param {Boolean} [required=false] Campo obligatorio.
     * @param {Boolean} [disabled=false] Selección deshabilitada.
     *
     * @requires app.component:download
     * @requires app.component:loading
     */
    angular.module('app')
        .component('fileUpload', {
            templateUrl: 'app/components/form/archivos/fileupload/file-upload.html',
            controllerAs: 'ctrl',
            controller: Controller,
            bindings: {
                model: '=',
                id: '@',
                label: '@',
                tooltip: '@',
                accept: "@",
                url: "@",
                size: '<',
                authorities: "@",
                onSelected: "<",
                disableDrop: '<',
                required: '<',
                disabled: '<'
            }
        });

    /* @ngInject */
    function Controller(Upload, AlertService) {
        var vm = this;

        vm.onArchivoSelect = onArchivoSelect;
        vm.eliminarArchivo = eliminarArchivo;

        vm.model = vm.model || {};
        vm.subido = !!vm.model.path;

        function onArchivoSelect(files) {
            if (files && files.length) {
                vm.progresoArchivo = true;
                vm.model.path = files[0].name;
                vm.model.eliminar = false;

                Upload.upload({
                    url: "api/archivos-temporales",
                    data: {
                        file: files[0],
                        ext: vm.accept
                    },
                    method: 'POST'
                }).progress(function (evt) {
                    console.log(parseInt(100.00 * evt.loaded / evt.total) + '%');
                }).success(function (response) {
                    vm.progresoArchivo = false;
                    vm.subido = true;
                    vm.model.archivoTemporal = response.msg;

                    if (vm.onSelected) {
                        vm.onSelected(vm.model.archivoTemporal, vm.model.path);
                    }
                }).error(function (data) {
                    console.log("Error subiendo archivo");
                    vm.progresoArchivo = false;
                    vm.model.path = null;
                    // form.$setValidity('archivoSubido', true);
                    if (data && data.msg === 'invalid extension') {
                        AlertService.error("error.invalidextension", {archivo: files[0].name});
                    }
                    else {
                        AlertService.error("admin.form.error.fail");
                    }
                });
            }
        }

        function eliminarArchivo() {
            vm.model.path = null;
            vm.model.eliminar = true;
            vm.subido = false;
        }

    }


})();
