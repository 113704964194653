(function (angular) {
    'use strict';

    // estatica: Objeto a modificar.
    // idiomas: Idiomas en los que pueden estar las páginas estáticas.

    angular
        .module('app')
        .component('estaticaForm', {
            bindings: {
                item: '<',
                idiomas: '<'
            },
            templateUrl: 'app/components/estaticas/admin/estatica-form.admin.html',
            controllerAs: 'ctrl',
            controller: function ($rootScope, Estatica) {
                var vm = this;

                vm.mode = 'edit';
                vm.canSave = true;

                vm.validar = function () {
                    for (var i = 0; i < vm.idiomas.length; i++) {
                        var idioma = vm.idiomas[i];
                        var estaticaI18n = vm.item.descripciones[idioma];
                        if (estaticaI18n.titulo === undefined) {
                            vm.active = i;
                            i = vm.idiomas.length;
                            break;
                        }
                    }
                };

                vm.save = function () {
                    Estatica.save(vm.item).$promise.then(function (data) {
                        vm.item = data;
                    }).catch(function (response) {
                        $rootScope.mostrarErrores(response, vm.form);
                    });

                };
            }
        });
})(angular);
