(function () {
    'use strict';

    /**
     * @ngdoc component
     * @name app.component:contactoForm
     * @description
     * Formulario de contacto que envía un email al administrador de la plataforma (el email es configurable).
     *
     */
    angular
        .module('app')
        .component('contactoForm', {
            templateUrl: 'app/components/contactoform/contacto-form.html',
            controllerAs: 'ctrl',
            controller: Controller
        });

    /* @ngInject */
    function Controller($rootScope, Contacto, Permisos, COMPONENTES, vcRecaptchaService) {
        var vm = this;
        vm.captcha = {
            resuelto: false,
            response: undefined
        };

        vm.mostrarCaptcha = Permisos.tienePermisos('all', COMPONENTES.contacto.captcha);

        function init() {
            vm.item = new Contacto();
            vm.terminos = false;
        }

        // Guardar formulario
        vm.save = function () {
            vm.item.captchaToken = vm.captcha.response;

            vm.item.$save().then(function () {
                init();
                vm.form.$setPristine();
                vcRecaptchaService.reload();
            }).catch(function (response) {
                $rootScope.mostrarErrores(response, vm.form);
            });
        };

        vm.captchaSuccess = function(response) {
            vm.captcha.resuelto = true;
            vm.captcha.response = response.response;
        };

        init();
    }

})();
