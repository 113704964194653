(function () {
    'use strict';

    // Página temporal a la que se accede al pulsar en el email el email que se envió para notificar la nueva legistación (RGPD)

    angular
        .module('app')
        .config(stateConfig);

    /* @ngInject */
    function stateConfig($stateProvider) {
        $stateProvider.state('confirmacion-consentimento', {
            parent: 'public',
            url: '/confirmacion-consentimento',
            views: {
                'content@': {
                    template: '<estatica id="confirmacion-consentimento" bd="false"></estatica>'
                }
            }
        });
    }
})();
