(function (angular) {
    'use strict';

    /**
     * @ngdoc component
     * @name app.component:login
     * @description
     * Componente que muestra un formulario para acceder al espacio de usuario.
     *
     */
    angular
        .module('app')
        .component('login', {
            templateUrl: 'app/components/aspg/usuarios/login/login.html',
            controllerAs: 'ctrl',
            /* @ngInject */
            controller: function ($rootScope, $state, $timeout, Auth, Principal) {
                var vm = this;

                vm.user = {};
                vm.rememberMe = false;

                var stateXogos = "enXogo/base";

                Principal.identity().then(function (data) {
                    if (data) {
                        $state.go(stateXogos);
                    }
                });

                $timeout(function () {
                    angular.element('.form-group:eq(0)>input').focus();
                });

                vm.login = function () {
                    Auth.login({
                        username: vm.username,
                        password: vm.password,
                        rememberMe: vm.rememberMe
                    }).then(function () {
                        $state.go(stateXogos);
                    }).catch(function (response) {
                        $rootScope.mostrarErrores(response, vm.form);
                    });

                }
            }
        });
})(angular);
