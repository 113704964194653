(function () {
    'use strict';

    /**
     * @ngdoc component
     * @name app.component:loading
     *
     * @param {Boolean} isLoading Booleano para saber si se muestra o no
     * @param {String} [class=''] Clase a mayores de la que tiene por defecto (_loading_)
     * @param {String} [img=loading.svg] Ruta al icono de carga
     *
     */
    angular
        .module('app')
        .component('loading', {
            template: '<img ng-show="$ctrl.isLoading" ' +
            'ng-src="{{$ctrl.img || \'assets/images/loading.svg\'}}" ' +
            'class="loading {{$ctrl.class}}"/>',
            bindings: {
                isLoading: '<',
                class: '@',
                img: '@'
            }
        });

})();
