(function () {
    'use strict';

    angular.module('app')
        .factory('Rss', Service);

    /* @ngInject */
    function Service($resource) {
        var entidad = "rss";
        return $resource('api/' + entidad, {}, {
            query: {
                method: 'GET',
                isArray: true,
                ignoreLoadingBar: true
            }
        });
    }

})();
