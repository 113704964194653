(function () {
    'use strict';

    angular.module('app')
        .factory('Estatica', Service);

    /* @ngInject */
    function Service($resource) {
        var url = "api/estaticas";
        return $resource(url + '/:nombre', {}, {
            'getByNombreIdioma': {
                method: 'GET',
                url: url + '/getByNombreIdioma'
            }
        });
    }
})();
