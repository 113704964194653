(function () {
    'use strict';

    angular.module('app')
        .factory('RecursoEducativo', Service);

    /* @ngInject */
    function Service(ResourceHelper) {
        var entidad = "recurso-educativo";
        return ResourceHelper.getResource(entidad, {}, {
            bajar: {
                method: 'GET',
                url: 'api/' + entidad + '/bajar',
                params: {
                    id: null
                }
            },
            subir: {
                method: 'GET',
                url: 'api/' + entidad + '/subir',
                params: {
                    id: null
                }
            },
            findByMateriaUrl: {
                method: 'GET',
                url: 'api/' + entidad + '/findByMateriaUrl',
                params: {
                    url: null
                },
                isArray: true
            }
        });
    }

})();
