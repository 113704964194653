(function () {
    'use strict';

    angular
        .module('app')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('validar', {
                parent: 'public',
                url: '/validar?key',
                data: {
                    pageTitle: 'global.menu.activar'
                },
                views: {
                    'content@': {
                        template: '<h1 translate="enXogo.titulo"></h1>' +
                        '<validar></validar>'
                    }
                },
                resolve: {
                    /* @ngInject */
                    translatePartialLoader: function ($translatePartialLoader) {
                        $translatePartialLoader.addPart('componentes/enXogo');
                    }
                }
            });
    }
})();
