(function (angular) {
    'use strict';

    angular
        .module('app')
        .directive('ellipsis', ['$timeout', function ($timeout) {
            return function (scope, element, attrs) {
                var lineas = attrs.ellipsis;
                var truncar_palabra = attrs.ellipsisTruncarPalabra;

                var ellipsis = new Ellipsis({lines: lineas, break_word: truncar_palabra});

                // Esperamos a que los elementos sean renderizados para que tengan
                // seteados los tamaños
                $timeout(function(){
                    ellipsis.add(element);
                });
            };
        }]);
})(angular);
