(function () {
    'use strict';

    /**
     * @ngdoc service
     * @name app.service:ResourceHelper
     * @description
     * Clase de ayuda para generar un `$resource` de una entidad.
     *
     */
    angular.module('app')
        .factory('ResourceHelper', ResourceHelper);

    /* @ngInject */
    function ResourceHelper($resource) {

        return {
            getResource: getResource
        };

        /**
         * @ngdoc function
         * @name getResource
         * @methodOf app.service:ResourceHelper
         * @param {String} entidad Nombre de la endidad. Coincide con el nombre que se le de en la API REST
         * @param {Object} params Parámetros adicionales que se pasan al $resource
         * @param {Object} newFunctions Objecto que define las nuevas funciones o las que hay que sobreescribir
         * @returns {Object} `$resource`
         */
        function getResource(entidad, params, newFunctions) {
            var functions = {
                'query': {
                    isArray: false
                }
            };
            angular.extend(functions, newFunctions);

            return $resource('api/' + entidad + '/:id', params, functions);
        }
    }
})();
