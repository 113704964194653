(function () {
    'use strict';

    angular
        .module('app')
        .component('museoFormAdmin', {
            templateUrl: 'app/components/aspg/museos/admin/museo/museo-form.admin.html',
            controller: Controller,
            controllerAs: 'ctrl',
            bindings: {
                item: '<',
                previousParams: '<'
            }
        });

    /* @ngInject */
    function Controller($rootScope, $timeout, $state, $stateParams, ModalService, MuseosUtil) {
        var vm = this;

        $timeout(function () {
            angular.element('.form-group:eq(0) input').focus();
        });

        vm.mode = $state.current.data.mode;
        vm.canSave = vm.mode === 'create' || vm.mode === 'edit';

        vm.urlBaseImagen = MuseosUtil.getRutaMuseos(vm.item.id);

        function init() {
        }

        // Guardar formulario
        vm.save = function () {
            vm.item.$save().then(function () {
                ModalService.closeComponent(vm.item, 'admin/museos/museo/list', vm.previousParams);
            }).catch(function (response) {
                $rootScope.mostrarErrores(response, vm.form);
            });
        };

        init();

    }

})();
