(function () {
    'use strict';

    angular
        .module('app')
        .component('novedadDetailsPublic', {
            templateUrl: 'app/components/novedades/public/novedad-details.public.html',
            controller: Controller,
            controllerAs: 'ctrl',
            bindings: {
                novedad: '<',
                ampliada: '<'
            }
        });

    /* @ngInject */
    function Controller(ModalService, NovedadUtil, Language) {
        var vm = this;

        vm.ruta = NovedadUtil.getRuta(vm.novedad.id);
        vm.lang = Language.getCurrent();

        if (vm.novedad.imagenPath !== null) {
            vm.imagen = 'url("' + vm.ruta + 'n_' + vm.novedad.imagenPath + '")';
        } else {
            vm.imagen = 'url("assets/images/novedades/novidades.jpg")';
        }

        vm.abrirImagen = function () {
            if (vm.novedad.imagenPath !== null) {
                ModalService.openImagen(vm.ruta + vm.novedad.imagenPath);
            }
        };
    }

})();
