(function () {
    'use strict';

    angular.module('app')
        .factory('FeedRss', Service);

    /* @ngInject */
    function Service(ResourceHelper) {
        var entidad = "feed-rss";
        return ResourceHelper.getResource(entidad, {}, {});
    }

})();
