(function () {
    'use strict';

    angular
        .module('app')
        .component('museoDetailsPublic', {
            templateUrl: 'app/components/aspg/museos/public/museo/museo-details.public.html',
            controller: Controller,
            controllerAs: 'ctrl',
            bindings: {
                museo: '<'
            }
        });

    /* @ngInject */
    function Controller(MuseosUtil) {
        var vm = this;

        // Imágen del museo
        var urlBaseImagen = MuseosUtil.getRutaMuseos(vm.museo.id);
        vm.imagen = MuseosUtil.obtenerImagen(urlBaseImagen, vm.museo.imagenPath);
    }

})();
