(function () {
    'use strict';

    angular
        .module('app')
        .component('adminFormAdmin', {
            templateUrl: 'app/admin/user/admin/admin-form.admin.html',
            controller: Controller,
            controllerAs: 'ctrl',
            bindings: {
                item: '<',
                previousParams: '<',
                roles: '<'
            }
        });

    /* @ngInject */
    function Controller($rootScope, $timeout, $state, Auth, ModalService) {
        var vm = this;

        $timeout(function () {
            angular.element('.form-group:eq(0)>input')[0].focus();
        });

        vm.mode = $state.current.data.mode;
        vm.canSave = vm.mode === 'create' || vm.mode === 'edit';

        // TODO: Permitir la edición de administradores

        vm.save = function () {
            vm.item.rol = "ROLE_ADMIN";
            Auth.createAccount(vm.item).then(function () {
                ModalService.closeComponent(vm.item, 'admin/admin/list', vm.previousParams);
            }).catch(function (response) {
                $rootScope.mostrarErrores(response, vm.form);
            });
        };

    }

})();
