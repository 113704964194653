(function () {
    'use strict';

    /**
     * @ngdoc component
     * @name app.component:imagenCrop
     * @description
     * Componente para la subida de una imagen, permitiendo seleccionar un área de la misma.
     *
     * _Referencia:_ https://github.com/CrackerakiUA/ui-cropper/wiki/Options
     *
     * @param {Object} model Imagen
     * @param {String} carpeta Ruta a la carpeta de la imagen.
     * @param {Number} ratio Ratio que tiene que tener la imagen.
     * [1 = cuadrado; 1.x = horizontal; 0.x = vertical; null = sin restricciones.]
     * @param {String} [area=rectangle] Área de recorte. Si se indica un área diferente a `rectangle`, ratio tiene que ser null.
     * {rectangle|circle|square}
     * @param {Number} indice Necesario si las imágenes van dentro de un bucle, por ejemplo.
     * @param {Function} onUploadedImage Función de callback que se llama cuando se selecciona una imagen.
     * @param {Function} onRemoveImage Función de callback que se llama cuando se elimina una imagen.
     *
     * @requires app.component:loading
     */
    angular.module('app')
        .component('imagenCrop', {
            templateUrl: "app/components/form/archivos/imagencrop/imagen-crop.html",
            controllerAs: 'ctrl',
            controller: Controller,
            bindings: {
                model: "=",
                carpeta: "@",
                ratio: '<',
                area: '@',
                indice: "<",
                onUploadedImage: '&',
                onRemoveImage: '&'
            }
        });

    /* @ngInject */
    function Controller($ocLazyLoad, Upload, AlertService) {
        var vm = this;

        $ocLazyLoad.load({
            name: 'uiCropper',
            files: [
                'assets/bower/ui-cropper/ui-cropper.js',
                'assets/bower/ui-cropper/ui-cropper.css'
            ]
        }).then(function () {
            vm.editPreview = false;
            vm.myCroppedImage = '';
            vm.myImage = '';

            function init() {
                vm.model = vm.model || {};
                vm.area = vm.area || 'rectangle';

                if (!vm.model.rutaImagen) {
                    if (vm.model.path) {
                        vm.model.rutaImagen = vm.carpeta || "";
                        if (!vm.model.rutaImagen.endsWith("/")) {
                            vm.model.rutaImagen += "/";
                        }
                        vm.model.rutaImagen += "n_" + vm.model.path;

                        vm.myCroppedImage = vm.model.rutaImagen;
                    } else {
                        vm.model.rutaImagen = null;
                    }
                }
            }

            function onUploaded() {
                vm.onUploadedImage();
            }

            vm.isSubmitted = function (form) {
                while (!!form) {
                    if (form.$submitted) return true;
                    form = form.$$parentForm;
                }
                return false;
            };

            vm.eliminarImagen = function () {
                vm.model.eliminar = true;
                vm.model.rutaImagen = null;
                vm.model.path = null;
                vm.model.archivoTemporal = null;
                vm.myCroppedImage = '';

                // Llamamos al callback para eliminar el elemento de la lista
                vm.onRemoveImage({imagen: vm.model});
            };

            function blobToFile(theBlob, fileName) {
                // A Blob() is almost a File() - it's just missing the two
                // properties below which we will add
                theBlob.lastModifiedDate = new Date();
                theBlob.name = fileName;
                return theBlob;
            }

            function dataURItoBlob(dataURI, type) {
                // convert base64 to raw binary data held in a string
                var byteString = atob(dataURI.split(',')[1]);

                // write the bytes of the string to an ArrayBuffer
                var ab = new ArrayBuffer(byteString.length);
                var ia = new Uint8Array(ab);
                for (var i = 0; i < byteString.length; i++) {
                    ia[i] = byteString.charCodeAt(i);
                }

                // write the ArrayBuffer to a blob, and you're done
                return new Blob([ab], {type: type});
            }

            vm.onImagenSelect = function (files) {
                vm.editPreview = false;
                vm.model.eliminar = false;
                if (files && files.length) {

                    vm.model.path = null;
                    vm.progresoImagen = true;

                    // Subimos imagen
                    Upload.upload({
                        url: "api/archivos-temporales",
                        data: {
                            file: blobToFile(dataURItoBlob(files, 'image/png'), vm.myImage.name),
                            ext: "jpeg,png,gif,jpg"
                        },
                        method: 'POST'
                    }).success(function (response) {
                        vm.progresoImagen = null;
                        vm.model.archivoTemporal = response.msg;
                        vm.model.path = vm.myImage.name;
                        onUploaded();
                    }).error(function (data) {
                        vm.progresoImagen = null;
                        vm.eliminarImagen();
                        if (data && data.msg === 'invalid extension') {
                            AlertService.error("error.invalidextension", {archivo: vm.myImage.name});
                        } else {
                            AlertService.error("admin.form.error.fail");
                        }
                    });
                }
            };

            vm.clickUpload = function () {
                var indice = vm.indice || "";
                angular.element("#inputFoto" + indice).click();
            };

            vm.preview = function (files) {
                vm.myImage = files[0];
                vm.editPreview = true;
            };

            init();

        });

    }

})();
