(function () {
    'use strict';

    angular
        .module('app')
        .config(stateConfig);

    /* @ngInject */
    function stateConfig($stateProvider, STATE_HELPER, SALA_MUSEO_FORM_ADMIN) {
        var config = SALA_MUSEO_FORM_ADMIN();
        var base = "admin/museos/salaMuseo";
        var params = {
            parent: "admin/museos/museo",
            view: 'contentChildren',
            base: base,
            baseUrl: "/{museoid:int}/sala",
            translateBase: "museos.salaMuseo.admin.",
            templateList: '<sala-museo-list-admin></sala-museo-list-admin>',
            templateForm: config.component
        };

        var state = STATE_HELPER(params);
        // Estado Padre
        angular.merge(state.parent, {
            data: {
                authorities: ['ROLE_ADMIN']
            },
            views: {
                'content@': {
                    template: '<ng-include src="\'app/components/aspg/museos/admin/museos-breadcrumb.admin.html\'"></ng-include>' +
                    '<div ui-view="contentChildren"></div>'
                }
            },
            resolve: {
                /* @ngInject */
                museo: function ($stateParams, Museo) {
                    // TODO: ¿Optimizar y obtener sólo ID y nombre?
                    if ($stateParams.museoid) {
                        return Museo.get({id: $stateParams.museoid}).$promise;
                    } else return {};
                }
            }
        });
        // Listar
        angular.merge(state.list, {});
        // Crear
        angular.merge(state.create, {
            resolve: config.resolve
        });
        // Editar
        angular.merge(state.edit, {
            resolve: angular.merge(config.resolve, {
                /* @ngInject */
                item: function (SalaMuseo, $stateParams) {
                    return SalaMuseo.get({id: $stateParams.id}).$promise;
                }
            })
        });

        // Se definen los estados
        $stateProvider
            .state(params.base, state.parent)
            .state(state.list.name, state.list)
            .state(state.create.name, state.create)
            .state(state.edit.name, state.edit);
    }
})();
