(function () {
    'use strict';

    angular.module('app')
        .factory('Novedad', Service);

    /* @ngInject */
    function Service(ResourceHelper) {
        var entidad = "novedad";
        return ResourceHelper.getResource(entidad, {}, {
            getByUrl: {
                method: 'GET',
                url: 'api/' + entidad + '/getByUrl',
                params: {
                    url: null,
                    idioma: null
                }
            },
            findPublic: {
                method: 'GET',
                url: 'api/' + entidad + '/findPublic'
            }
        });
    }

})();
