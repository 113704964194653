(function (angular) {
    'use strict';

    angular
        .module('app')
        .component('unsuscribe', {
            templateUrl: 'app/components/aspg/usuarios/unsuscribe/unsuscribe.html',
            controllerAs: 'ctrl',
            /* @ngInject */
            controller: function ($stateParams, Suscriptor) {
                var vm = this;

                vm.cancelarSuscripcion = function(){
                    var suscripcionId = $stateParams.key;
                    Suscriptor.cancelarSuscripcion({key: suscripcionId}).$promise.then(function(){
                        vm.success = true;
                    });
                };
            }
        });
})(angular);
