(function () {
    'use strict';

    angular.module('app')
        .factory('Curso', Service);

    /* @ngInject */
    function Service($resource) {
        var urlBase = "api/cursos";
        return $resource(urlBase, {}, {
            getUltimos: {
                method: 'GET',
                isArray: true,
                ignoreLoadingBar: true
            }
        });
    }

})();
