(function () {
    'use strict';

    /**
     * @ngdoc filter
     * @name app.filter:num
     *
     * @description
     * Convierte un String en un Int.
     *
     * @returns {int} Devuelve el String convertido en entero
     */
    angular
        .module('app')
        .filter('num', function () {
            return function (input) {
                return parseInt(input, 10);
            }
        });

})();
