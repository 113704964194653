(function () {
    'use strict';

    angular.module('app')
        .factory('NgImageGalleryHelper', NgImageGalleryHelper);

    /* @ngInject */
    function NgImageGalleryHelper() {
        return {
            toNgImageGalleryImagen: toNgImageGalleryImagen
        };

        function toNgImageGalleryImagen(id, urlImagen, urlThumbnail, titulo) {
            return {id: id, url: urlImagen, thumbUrl: urlThumbnail, title: titulo};
        }
    }
})
();
