(function () {
    'use strict';

    angular
        .module('app')
        .config(stateConfig);

    /* @ngInject */
    function stateConfig($stateProvider) {
        $stateProvider
            .state('admin/usuario', {
                parent: 'admin',
                url: '/admin/usuarios',
                redirectTo: 'admin/usuario/juegos',
                data: {
                    authorities: ['ROLE_ADMIN'],
                    hideBreadcrumb: true
                },
                views: {
                    'content@': {
                        templateUrl: 'app/components/aspg/usuarios/admin/usuarios-template.admin.html'
                    }
                },
                resolve: {
                    /* @ngInject */
                    translatePartialLoader: function ($translatePartialLoader) {
                        $translatePartialLoader.addPart('componentes/usuarios');
                    }
                }
            })
            .state('admin/usuario/juegos', {
                parent: 'admin/usuario',
                url: '/juegos/:page',
                data: {
                    pageTitle: 'usuarios.admin.usuarios.titulo'
                },
                views: {
                    'contentUsuario': {
                        template: '<usuarios-list-admin></usuarios-list-admin>'
                    }
                }
            })
            .state('admin/usuario/suscriptor', {
                parent: 'admin/usuario',
                url: '/suscriptores/:page',
                data: {
                    pageTitle: 'usuarios.admin.suscriptores.titulo'
                },
                views: {
                    'contentUsuario': {
                        template: '<suscriptores-list-admin></suscriptores-list-admin>'
                    }
                }
            })
            .state('admin/usuario/email', {
                parent: 'admin/usuario',
                url: '/email',
                data: {
                    pageTitle: 'usuarios.admin.email.titulo'
                },
                views: {
                    'contentUsuario': {
                        template: '<enviar-mail suscriptores="$resolve.suscriptores"></enviar-mail>'
                    }
                },
                resolve: {
                    /* @ngInject */
                    suscriptores: function (Suscriptor) {
                        return Suscriptor.query({infoGeneral: true, infoJuegos: true, infoTienda: true}).$promise;
                    }
                }
            });
    }
})();
