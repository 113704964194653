(function (angular) {
    'use strict';

    /**
     * @ngdoc service
     * @name app.service:Menu
     * @description
     * Servicio con operaciones para el uso de MiMenu.
     *
     * Referencia: {@link https://angular-ui.github.io/bootstrap/#modal}
     *
     */
    angular
        .module('app')
        .factory('Menu', Menu);

    Menu.$inject = ['$q', '$http'];

    function Menu($q, $http) {
        return {
            /**
             * @ngdoc function
             * @name getMenu
             * @methodOf app.service:Menu
             *
             * @param {String} name Nombre del archivo. Ej: `menu-main`
             *
             * @returns {Object} Promesa que devolverá el contenido del archivo JSON.
             */
            getMenu: getMenu
        };

        function getMenu(name) {
            var deferred = $q.defer();
            $http.get('menu/' + name + '.json').then(function (response) {
                deferred.resolve(response.data);
            });

            return deferred.promise;
        }
    }
})(angular);
