(function () {
    'use strict';

    angular
        .module('app')
        .config(stateConfig);

    /* @ngInject */
    function stateConfig($stateProvider) {
        $stateProvider.state('cookies', {
            parent: 'public',
            url: '/cookies',
            data: {
                pageTitle: 'global.menu.cookies'
            },
            views: {
                'content@': {
                    template: '<estatica id="cookies" bd="false"></estatica>'
                }
            }
        })

    }
})();
