(function () {
    'use strict';

    angular
        .module('app')
        .config(stateConfig);

    /* @ngInject */
    function stateConfig($stateProvider) {
        $stateProvider
            .state('novedad', {
                parent: 'public',
                url: '/novedades',
                redirectTo: 'novedades/list',
                data: {
                    authorities: [],
                    pageTitle: 'novedades.titulo'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/components/novedades/public/novedad-template.public.html',
                        controllerAs: 'ctrl'
                    }
                },
                resolve: {
                    /* @ngInject */
                    translatePartialLoader: function ($translatePartialLoader) {
                        $translatePartialLoader.addPart('componentes/novedades');
                    },
                    /* @ngInject */
                    categorias: function (CategoriaNovedad, Permisos, COMPONENTES) {
                        // Si no se tienen permisos, no se el menú de categorías
                        if (Permisos.tienePermisos('list', COMPONENTES.novedades.categoriasPublic)) {
                            return CategoriaNovedad.query({
                                sortProperty: 'nombre',
                                sortDirection: 'ASC'
                            }).$promise.then(function (data) {
                                return data;
                            });
                        }
                    },
                    /* @ngInject */
                    menu: function ($translate, categorias) {
                        if (categorias) {
                            var categoriasMenu = [{
                                "label": $translate.instant("novedades.categoriaNovedad.todas"),
                                "state": "novedad/list({categoriaUrl: null})"
                            }];
                            for (var i = 0; i < categorias.content.length; i++) {
                                categoriasMenu.push({
                                    "label": categorias.content[i].nombre,
                                    "state": "novedad/listByCategoria({categoriaUrl: '" + categorias.content[i].url + "', page: '1'})"
                                });
                            }
                            return categoriasMenu;
                        }
                    },
                    /* @ngInject */
                    rssUrl: function (Language, RutasService) {
                        return RutasService.getBaseUrl() + "/rss/novedades?idioma=" + Language.getCurrent();
                    }
                }
            })
            .state('novedades/list', {
                parent: 'novedad',
                url: '/p/{page}?etiqueta',
                params: {
                    categoriaUrl: 'novedades',
                    page: '1',
                    etiqueta: null
                },
                views: {
                    'contentNovedades': {
                        template: '<novedad-list-public novedades="$resolve.novedades" etiqueta="$resolve.etiqueta" categorias-novedad="$resolve.categoria"></novedad-list-public>'
                    }
                },
                resolve: {
                    /* @ngInject */
                    novedades: function ( $stateParams, Novedad, Language, NG_LIST_DEFAULT_PARAMS_PUBLIC) {
                        var filter = {
                            categoriaUrl: $stateParams.categoriaUrl,
                            page: $stateParams.page ? $stateParams.page - 1 : null,
                            idioma: Language.getCurrent(),
                            size: NG_LIST_DEFAULT_PARAMS_PUBLIC.size,
                            sortProperty: 'n.fecha',
                            sortDirection: 'DESC',
                            etiqueta: $stateParams.etiqueta
                        };
                        return Novedad.findPublic(filter).$promise;
                    },
                    etiqueta: function ($stateParams){
                        return $stateParams.etiqueta;
                    },
                    categoria: function ($stateParams){
                        return $stateParams.categoriaUrl;
                    }

                }
            })
            .state('novedad/listByCategoria', {
                parent: 'novedad/list',
                url: '/categoria/:categoriaUrl'
            })
            .state('novedades/details', {
                parent: 'novedad',
                url: '/{url}',
                views: {
                    'content@': {
                        template: '<h1 translate="novedades.ultimas"></h1>' +
                        '<novedad-details-public novedad="$resolve.novedad" ampliada="true"></novedad-details-public>'
                    }
                },
                resolve: {
                    /* @ngInject */
                    novedad: function ($stateParams, Novedad, Language) {
                        return Novedad.getByUrl({
                            url: $stateParams.url,
                            idioma: Language.getCurrent()
                        }).$promise.then(function (data) {
                            return data;
                        });
                    }
                }
            })
    }
})();
