(function () {
    'use strict';

    /**
     * @ngdoc service
     * @name app.service:Permisos
     * @description
     * Operaciones para tratar con los permisos configurables en la aplicación (en `config.js`).
     *
     */
    angular.module('app')
        .factory('Permisos', Service);

    /* @ngInject */
    function Service(PERMISOS) {
        return {
            /**
             * @ngdoc function
             * @name tienePermisos
             * @methodOf app.service:Permisos
             * @description
             * Esta operación calcula si se tiene permisos para hacer una acción.
             *
             * Jerarquía:
             * - 'none'
             * - 'list'
             * - 'edit'
             * - 'create'
             * - 'all'
             *
             * Es decir, el permiso 'create' implica también el permiso 'list' y 'edit'.
             *
             * Si se puede crear, también se puede eliminar.
             *
             * En algunas ocasiones simplemente se utilizará 'none' para ocultar un elemento, y 'all' (o null) para que se muestre siempre.
             *
             * Si no se define la propiedad en config, no se establecerá ninguna restricción.
             *
             * @param {String} actual Permiso que tiene el elemento (o acción) actual.
             * @param {String} config Permiso global de la aplicación con el que se va a comparar.
             * @returns {Boolean} True si tiene permiso, false en caso contrario.
             *
             * @requires app.object:permisos_constants
             */
            tienePermisos: tienePermisos
        };

        function tienePermisos(actual, config) {
            return PERMISOS(actual, config);
        }

    }

})();
