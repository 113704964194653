(function () {
    'use strict';

    angular
        .module('app')
        .component('configuracionFormAdmin', {
            templateUrl: 'app/components/configuracion/configuracion-form.admin.html',
            controller: Controller,
            controllerAs: 'ctrl',
            bindings: {
                configuraciones: '<'
            }
        });

    /* @ngInject */
    function Controller($rootScope, $timeout, Configuracion) {
        var vm = this;

        $timeout(function () {
            angular.element('.form-group:eq(0) input').focus();
        });

        // Guardar formulario
        vm.save = function () {
            Configuracion.save(vm.configuraciones).$promise.catch(function (response) {
                $rootScope.mostrarErrores(response, vm.form);
            });
        };
    }

})();
