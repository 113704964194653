(function (angular) {
    'use strict';

    angular
        .module('app')
        .component('validar', {
            templateUrl: 'app/components/aspg/usuarios/validar/validar.html',
            controllerAs: 'ctrl',
            /* @ngInject */
            controller: function ($stateParams, Auth) {
                var vm = this;

                Auth.validateAccount($stateParams.key).then(function (result) {
                    vm.error = null;
                    vm.success = true;
                    vm.rol = result.data;
                }).catch(function () {
                    vm.error = 'ERROR';
                });
            }
        });
})(angular);
