(function () {
    'use strict';

    /**
     * @ngdoc component
     * @name app.component:download
     * @description
     * Componente para la descarga de archivos.
     *
     * @param {Object} archivo Nombre del archivo
     * @param {String} url URL del directorio donde se encuentra el archivo
     * @param {Boolean} [showLabel=false] Booleano para mostrar u ocultar el nombre del archivo
     *
     */
    angular.module('app')
        .component('download', {
            templateUrl: 'app/components/form/archivos/download/download.html',
            controllerAs: 'ctrl',
            controller: Controller,
            bindings: {
                archivo: "<",
                url: "@",
                showLabel: "<"
            }
        });

    function Controller() {
        var vm = this;

        vm.descargarArchivo = descargarArchivo;

        function descargarArchivo() {
            window.open(vm.url + vm.archivo, '_blank');
        }
    }

})();
