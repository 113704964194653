(function () {
    'use strict';

    angular
        .module('app')
        .component('novedadFormAdmin', {
            templateUrl: 'app/components/novedades/admin/novedad/novedad-form.admin.html',
            controller: Controller,
            controllerAs: 'ctrl',
            bindings: {
                item: '<',
                previousParams: '<',
                permisosCategoria: '<',
                categorias: '<',
                idiomas: '<'
            }
        });

    /* @ngInject */
    function Controller($rootScope, $timeout, $state, NovedadUtil, ModalService, FormUtils, Language, CATEGORIA_NOVEDAD_FORM_ADMIN) {
        var vm = this;

        $timeout(function () {
            angular.element('.tab-content .form-group:eq(0) input').focus();
        });

        vm.mode = $state.current.data.mode;
        vm.canSave = vm.mode === 'create' || vm.mode === 'edit';

        vm.lang = Language.getDefault();

        vm.urlBaseImagen = NovedadUtil.getRuta(vm.item.id);

        vm.validar = function () {
            vm.active = FormUtils.validarTabs(vm.form, vm.active, vm.idiomas);
        };

        // Guardar formulario
        vm.save = function () {
            if (!vm.item.url || vm.actualizarUrl) {
                vm.item.tituloParaUrl = vm.item.descripciones[vm.lang].titulo;
            }

            vm.item.$save().then(function () {
                ModalService.closeComponent(vm.item, 'admin/novedad/list', vm.previousParams);
            }).catch(function (response) {
                $rootScope.mostrarErrores(response, vm.form);
            });
        };

        vm.openCategoriaModal = function () {
            ModalService.openComponent('novedades.categoriaNovedad.admin.create.title', CATEGORIA_NOVEDAD_FORM_ADMIN, {size: 'md'}).result.then(function (result) {
                // Asociamos el nuevo elemento a la entidad
                vm.categorias.push(result);
                vm.item.categoriaNovedad = result;
            });
        };

    }

})();
