(function (angular) {
    'use strict';

    /**
     * @ngdoc component
     * @name app.component:ordenable
     * @description
     * Componente que añade dos botones (subir/bajar) para poder reordenar una lista de elementos.
     *
     * @param {Number} id Identificador de la entidad que se quiere reordenar.
     * @param {Object} resource Servicio que contiene las operaciones de `bajar` y `subir`.
     * @param {Function} onChange Función de callback que se llama una vez se hace la modificación.
     * Ejemplo: `ctrl.tableParams.reload()`
     *
     */
    angular
        .module('app')
        .component('ordenable', {
            bindings: {
                id: '<',
                resource: '<',
                onChange: '&'
            },
            templateUrl: 'app/components/ordenable/ordenable.html',
            controller: function () {
                var vm = this;

                vm.bajar = function () {
                    vm.resource.bajar({id: vm.id}).$promise.then(function () {
                        vm.onChange();
                    });
                };

                vm.subir = function () {
                    vm.resource.subir({id: vm.id}).$promise.then(function () {
                        vm.onChange();
                    });
                };
            },
            controllerAs: 'ctrl'
        });

})(angular);
