(function () {
    'use strict';

    angular
        .module('app')
        .component('enviarMail', {
            templateUrl: 'app/components/aspg/usuarios/admin/email/enviar-mail.html',
            controller: Controller,
            controllerAs: 'ctrl',
            bindings: {
                suscriptores: '<'
            }
        });

    /* @ngInject */
    function Controller(Suscriptor) {
        var vm = this;
        vm.email = {
            asunto: null,
            cuerpo: null
        };

        vm.tipoDestinatario = 'grupo';
        vm.infoGeneral = false;
        vm.infoJuegos = false;
        vm.infoTienda = false;
        vm.suscriptor = null;

        vm.enviar = function () {
            var destinatario;

            switch (vm.tipoDestinatario){
                case 'grupo':
                    destinatario = {infoGeneral: vm.infoGeneral, infoJuegos: vm.infoJuegos, infoTienda: vm.infoTienda};
                    if (!destinatario.infoJuegos && !destinatario.infoGeneral && !destinatario.infoTienda){
                        vm.errorDestinatario = true;
                        return;
                    }
                    break;
                case 'suscriptor':
                default:
                    destinatario = {email: vm.suscriptor.email};
            }

            Suscriptor.enviarEmail(destinatario, vm.email).$promise.then(function () {
                vm.success = true;
                vm.error = false;
            }).catch(function(){
                vm.error = true;
                vm.success = false;
            });
        };
    }

})();
