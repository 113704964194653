(function () {
    'use strict';

    angular
        .module('app')
        .config(stateConfig);

    /* @ngInject */
    function stateConfig($stateProvider) {
        $stateProvider.state('conoce', {
            parent: 'public',
            url: '/conece-a-aspg',
            data: {
                pageTitle: 'global.menu.conoce'
            },
            views: {
                'content@': {
                    template: '<estatica id="quensomos" bd="true"></estatica>'
                }
            }
        });
    }
})();
