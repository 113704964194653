(function () {
    'use strict';

    angular
        .module('app')
        .config(stateConfig);

    /* @ngInject */
    function stateConfig($stateProvider) {
        $stateProvider
            .state('boletin', {
                parent: 'public',
                url: '/boletines',
                redirectTo: 'boletin/list',
                data: {
                    authorities: [],
                    pageTitle: 'boletines.titulo'
                },
                resolve: {
                    /* @ngInject */
                    translatePartialLoader: function ($translatePartialLoader) {
                        $translatePartialLoader.addPart('componentes/boletines');
                    }
                }
            })
            .state('boletin/list', {
                parent: 'boletin',
                url: '/p/{page}',
                params: {
                    page: '1'
                },
                views: {
                    'content@': {
                        template: '<h1 translate="boletines.titulo"></h1>' +
                        '<boletin-list-public boletines="$resolve.boletines"></boletin-list-public>'
                    }
                },
                resolve: {
                    /* @ngInject */
                    boletines: function ($stateParams, Boletin, NG_LIST_DEFAULT_PARAMS_PUBLIC) {
                        return Boletin.query({
                            page: $stateParams.page ? $stateParams.page - 1 : null,
                            size: NG_LIST_DEFAULT_PARAMS_PUBLIC.size,
                            sortProperty: 'numero',
                            sortDirection: 'DESC'
                        }).$promise.then(function (data) {
                            return data;
                        });
                    }
                }
            });
    }
})();
