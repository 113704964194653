(function (angular) {
    'use strict';

    angular
        .module('app')
        .component('registro', {
            templateUrl: 'app/components/aspg/usuarios/registro/registro.html',
            controllerAs: 'ctrl',
            bindings: {
                mode: '@',
                usuario: '<'
            },
            /* @ngInject */
            controller: function ($rootScope, $timeout, $stateParams, UsuarioAspg) {
                var vm = this;

                $timeout(function () {
                    angular.element('.form-group>input:eq(0)').focus();
                });

                function initForm() {
                    if (vm.mode === 'create') {
                        vm.item = new UsuarioAspg();
                        vm.item.type = 'aspg';
                        vm.item.infoGeneral = false;
                        vm.item.infoJuegos = false;
                        vm.confirmPassword = "";
                    } else {
                        vm.item = vm.usuario;
                    }
                }

                initForm();

                // Guardar formulario
                vm.save = function () {
                    var accion;

                    switch (vm.mode) {
                        case 'edit':
                            accion = '$editar';
                            break;
                        case 'create':
                        default:
                            accion = '$save';
                    }

                    vm.item[accion]().then(function () {
                        vm.success = true;
                        initForm();
                    }).catch(function (response) {
                        $rootScope.mostrarErrores(response, vm.form);
                    });
                };
            }
        });
})(angular);
