(function () {
    'use strict';

    angular.module('app')
        .config(stateConfig);

    /* @ngInject */
    function stateConfig($stateProvider) {
        $stateProvider
            .state('admin', {
                abstract: true,
                parent: 'site',
                views: {
                    'css@': {
                        templateUrl: 'app/admin/admin.css.html'
                    },
                    'navbar@': {
                        template: '<mi-menu menu="$ctrl.menu" nav-collapsed="$ctrl.navCollapsed"></mi-menu>',
                        /* @ngInject */
                        controller: function (menuData, $window) {
                            this.menu = menuData;
                            this.navCollapsed = $window.innerWidth < 992;
                        },
                        controllerAs: '$ctrl'
                    },
                    'footer@': {
                        templateUrl: 'app/layouts/footer/footer.admin.html'
                    }
                },
                resolve: {
                    /* @ngInject */
                    isAdmin: function ($rootScope) {
                        $rootScope.isAdmin = true;
                    },
                    /* @ngInject */
                    menuData: function (Menu) {
                        return Menu.getMenu('menu-admin');
                    },
                    /* @ngInject */
                    translatePartialLoader: function ($translatePartialLoader) {
                        $translatePartialLoader.addPart('admin');
                    }
                }
            })
            .state('admin/login', {
                url: '/admin',
                parent: 'admin',
                redirectTo: 'login'
            });
    }
})();
