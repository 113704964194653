(function () {
    'use strict';

    angular.module('app')
        .factory('Boletin', Service);

    /* @ngInject */
    function Service(ResourceHelper) {
        var entidad = "boletin";
        return ResourceHelper.getResource(entidad, {}, {});
    }

})();
