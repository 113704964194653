(function () {
    'use strict';

    /**
     * @ngdoc component
     * @name app.component:multiSelect
     * @description
     * Componente para incluir un componente de etiquetas. Similar al componente tesauro pero en un mismo input.
     *
     * __Nota sobre el rendimiento:__ Hasta 50 no se nota ningún tiempo de espera. Sin embargo, a partir de 100 se nota que le cuesta abrir, pero es asumible.
     * Con 1000 elementos ya tarda varios segundos.
     *
     * Referencia: {@link http://dotansimha.github.io/angularjs-dropdown-multiselect/docs}
     *
     * @param {Object} model Objeto en el que se almacenará el resultado
     * @param {String} id ID del input
     * @param {String} label Etiqueta
     * @param {String} tooltip Etiqueta del tooltip
     * @param {String} [empty='multiselect.default'] Etiqueta cuando no hay elementos seleccionados
     * @param {Array} options Lista de opciones
     * @param {String} [display="nombre"] Propiedad que se mostrará al seleccionar
     * @param {String} template Información que se va a mostrar. Tiene que ir entre comillas
     * Ej: `'{{option.nombre}} <span ng-if=\'option.pais.nombre\'>({{option.pais.nombre}})</span>'`
     * @param {String} enumBase Texto base del enumerado. Con este String se sabe si se está mostrando un enumerado o un objeto. Ej: `enum.idiomas.`
     * @param {Object} extra Propiedades para sobreescribir en formato json. Sobreescriben las indicadas anteriormente
     *
     * @requires app.component:labelForm
     */
    angular
        .module('app')
        .component('multiSelect', {
            templateUrl: 'app/components/form/multiselect/multi-select.html',
            bindings: {
                model: '=',
                id: '@',
                label: '@',
                tooltip: '@',
                empty: '@',
                options: '<',
                display: '@',
                template: '<',
                enumBase: '@',
                extra: '<'

            },
            controller: Controller,
            controllerAs: 'ctrl'
        });

    /* @ngInject */
    function Controller($translate) {
        var vm = this;

        // Valores por defecto
        vm.display = vm.display || "nombre";

        if (!vm.model) {
            vm.model = [];
        }

        //

        vm.extraSettings = {
            smartButtonMaxItems: 4,

            enableSearch: true,
            clearSearchOnClose: true,

            selectedToTop: true,
            showUncheckAll: false,
            showCheckAll: false
        };

        if (!vm.enumBase) {
            angular.merge(vm.extraSettings, {
                displayProp: vm.display,
                searchField: vm.display,
                idProperty: 'id'
            });
        } else {
            // Enumerados
            angular.merge(vm.extraSettings, {
                // Función para personalizar el texto que se muestra en el botón
                smartButtonTextProvider: function (selectionArray) {
                    var text = "";
                    selectionArray.forEach(function (element, index) {
                        text += $translate.instant(vm.enumBase + element);
                        if (index + 1 < selectionArray.length) {
                            text += ", ";
                        }
                    });
                    return text;
                }
            });
        }

        if (vm.template) {
            vm.extraSettings.template = vm.template;
        }

        if (vm.extra) {
            angular.merge(vm.extraSettings, vm.extra);
        }

        // Traducciones de los textos
        vm.translations = {
            buttonDefaultText: vm.empty ? $translate.instant(vm.empty) : $translate.instant("multiselect.default"),
            searchPlaceholder: $translate.instant("multiselect.search"),
            selectionCount: $translate.instant("multiselect.selectioncount")
        };

        // Elemento seleccionado
        vm.onItemSelect = function (item) {

        };

    }

})();
