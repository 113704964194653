(function (angular) {
    'use strict';

    angular
        .module('app')
        .component('recuperarInit', {
            templateUrl: 'app/components/aspg/usuarios/recuperar/recuperar-init.html',
            controllerAs: 'ctrl',
            /* @ngInject */
            controller: function ($timeout, Auth) {
                var vm = this;

                $timeout(function () {
                    angular.element('[ng-model="ctrl.email"]').focus();
                });

                vm.recuperar = function (email) {
                    Auth.resetPasswordInit(email).then(function () {
                        vm.success = true;
                    });
                };
            }
        });
})(angular);
