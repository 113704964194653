(function () {
    'use strict';

    angular.module('app')
        .factory('BoletinUtil', Service);

    /* @ngInject */
    function Service() {
        return {
            getRutaImagen: getRutaImagen,
            getRutaArchivo: getRutaArchivo
        };

        function getRutaBase() {
            return '/upload/boletines/';
        }

        function getRutaImagen(id) {
            return getRutaBase() + id + '/';
        }

        function getRutaArchivo(id) {
            return getRutaBase() + id + '/';
        }
    }

})();
