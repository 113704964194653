(function () {
    'use strict';

    angular
        .module('app')
        .factory("ValidateConfig", factory);

    /* @ngInject */
    function factory($rootScope, $translate, bootstrap3ElementModifier, validator, defaultErrorMessageResolver, AlertService, Language) {
        return {
            initialize: initialize
        };

        function initialize() {
            // Mostrar iconos de validación
            bootstrap3ElementModifier.enableValidationStateIcons(true);
            // No resaltar elementos correctos
            validator.setValidElementStyling(false);
            // Validación sólo al hacer submit
            validator.defaultFormValidationOptions.validateOnFormSubmit = true;

            // Ubicación del fichero de mensajes de error
            defaultErrorMessageResolver.setI18nFileRootPath('i18n/auto-validate');
            defaultErrorMessageResolver.setCulture(Language.getCurrent());

            // Operación que añade errores en los campos correspondientes en un formulario
            $rootScope.mostrarErrores = function (response, form) {
                if (response.data && response.data.fieldErrors) {
                    var errores = false;
                    response.data.fieldErrors.forEach(function (error) {
                        console.log("Error de validación: ", error);
                        // FIXME: No se obtienen los mensajes de jcs-auto-validate .json
                        errores = form.setExternalValidation(error.field, error.code, $translate.instant(error.code));
                    });
                    if (errores) {
                        // Si se encuentran errores en el formulario, no se muestran las Alertas.
                        AlertService.clear();
                    }
                }
            };
        }
    }
})();
