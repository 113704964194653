(function () {
    'use strict';

    angular
        .module('app')
        .component('socialWidget', {
            templateUrl: 'app/components/socialwidget/social-widget.html',
            controller: function ($timeout, $cookies) {
                var vm = this;
                vm.cookiesAccepted = false

                if ($cookies.getObject("cc_cookie") !== undefined &&
                    $cookies.getObject("cc_cookie").level.includes('third_party_cookies')) {
                    vm.cookiesAccepted = true;
                    $timeout(function () {
                        FB.XFBML.parse();
                        twttr.widgets.createTimeline(
                            {
                                sourceType: "profile",
                                screenName: "asocpedagoxica"
                            },
                            document.getElementById("tweets")
                        );
                        twttr.widgets.load();
                    }, 500);
                } else {
                    vm.cookiesAccepted = false
                }
            },
            controllerAs: 'ctrl'
        });
})();
