(function (angular) {
    'use strict';

    /**
     * @ngdoc component
     * @name app.component:idiomas
     * @description
     * Componente para el cambio de idioma de la aplicación.
     *
     * Permite elegir entre la lista de idiomas existentes en la configuración de la aplicación (`/gulp/config.js`).
     *
     * Muestra los idiomas en el formato: `es | en`.
     *
     * La internacionalización de los nuevos idiomas hay que añadirla en `componentes.json`.
     *
     * Tiene dependencias con métodos definidos en app.module.js y `app.config.js`.
     *
     * @requires app.service:Language
     *
     */
    angular
        .module('app')
        .component('idiomas', {
            templateUrl: 'app/components/idiomas/idiomas.html',
            /* @ngInject */
            controller: function ($rootScope, $state, $localStorage, Language) {
                var vm = this;

                vm.idiomas = Language.getList();

                vm.changeLanguage = function (lang) {
                    if (lang) {
                        lang = lang.toLowerCase();
                        $rootScope.changeLanguage(lang);
                        $state.reload();
                    }
                    vm.lang = $localStorage.lang;
                };

                // Obtenemos el idioma activo
                vm.changeLanguage();

            },
            controllerAs: 'ctrl'
        });
})(angular);
