(function () {
    'use strict';

    angular.module('app')
        .factory('Materia', Service);

    /* @ngInject */
    function Service(ResourceHelper) {
        var entidad = "materia";
        return ResourceHelper.getResource(entidad, {}, {
            bajar: {
                method: 'GET',
                url: 'api/' + entidad + '/bajar',
                params: {
                    id: null
                }
            },
            subir: {
                method: 'GET',
                url: 'api/' + entidad + '/subir',
                params: {
                    id: null
                }
            },
            findByNivelUrl: {
                method: 'GET',
                url: 'api/' + entidad + '/findByNivelUrl',
                params: {
                    url: null
                },
                isArray: true
            }
        });
    }

})();
