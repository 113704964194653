(function () {
    'use strict';

    angular
        .module('app')
        .config(localStorageConfig);

    /* @ngInject */
    function localStorageConfig($localStorageProvider, $sessionStorageProvider, $translateProvider, LANGUAGES) {
        // Si se utilizan estas líneas de código, no se podrá acceder a localstorage con $localStorageProvider al configurar la aplicación.

        $localStorageProvider.setKeyPrefix('ASPG-');
        $sessionStorageProvider.setKeyPrefix('ASPG-');

        // Obtenemos el idioma de localStorage
        var lang = $localStorageProvider.get('lang');
        if (!lang || LANGUAGES.list.indexOf(lang) === 0) {
            $translateProvider.preferredLanguage(LANGUAGES.default);
            $localStorageProvider.set('lang', LANGUAGES.default);
        } else {
            $translateProvider.preferredLanguage(lang);
        }
    }

})();
