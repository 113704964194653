(function () {
    'use strict';

    angular
        .module('app')
        .component('webListPublic', {
            templateUrl: 'app/components/web/public/web-list.public.html',
            controller: Controller,
            controllerAs: 'ctrl',
            bindings: {
                webs: '<'
            }
        });

    /* @ngInject */
    function Controller(RutasService, WebUtil) {
        var vm = this;

        vm.rutaBase = RutasService.getBaseUrl() + WebUtil.getRutaWebPublica();
    }

})();
