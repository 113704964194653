(function () {
    'use strict';

    angular
        .module('app')
        .component('categoriaWebListPublic', {
            templateUrl: 'app/components/web/public/categoria-web-list.public.html',
            controller: Controller,
            controllerAs: 'ctrl',
            bindings: {
                categorias: '<'
            }
        });

    /* @ngInject */
    function Controller() {

    }

})();
