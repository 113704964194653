(function () {
    'use strict';

    angular
        .module('app')
        .config(stateConfig);

    /* @ngInject */
    function stateConfig($stateProvider) {
        $stateProvider
            .state('aulaDigital', {
                parent: 'public',
                url: '/aula-digital',
                redirectTo: 'aulaDigital/nivelesEducativos',
                data: {
                    authorities: [],
                    pageTitle: 'aulaDigital.titulo'
                },
                resolve: {
                    /* @ngInject */
                    translatePartialLoader: function ($translatePartialLoader) {
                        $translatePartialLoader.addPart('componentes/aulaDigital');
                    },
                    /* @ngInject */
                    nivelesEducativos: function (NivelEducativo) {
                        return NivelEducativo.query({sortProperty: 'orden'}).$promise.then(function (data) {
                            return data.content;
                        });
                    }
                }
            })
            .state('aulaDigital/nivelesEducativos', {
                parent: 'aulaDigital',
                url: '',
                views: {
                    'content@': {
                        template: '<h1 translate="aulaDigital.titulo"></h1>' +
                        '<p translate="aulaDigital.intro1"></p><p translate="aulaDigital.intro2"></p>' +
                        '<nivel-educativo-list-public niveles="$resolve.nivelesEducativos"></nivel-educativo-list-public>'
                    }
                }
            })
            .state('aulaDigital/materiasByNivel', {
                parent: 'aulaDigital',
                url: '/{nivelUrl}',
                redirectTo: 'aulaDigital/temasByMateria',
                views: {
                    'content@': {
                        templateUrl: 'app/components/auladigital/public/aula-digital-template.public.html',
                        controllerAs: 'ctrl'
                    }
                },
                resolve: {
                    /* @ngInject */
                    nivelUrl: function ($stateParams) {
                        return $stateParams.nivelUrl;
                    },
                    /* @ngInject */
                    materias: function ($stateParams, Materia) {
                        return Materia.findByNivelUrl({url: $stateParams.nivelUrl}).$promise.then(function (data) {
                            if (!data || !data.length) {
                                return [];
                            }
                            return data;
                        });
                    }
                }
            })
            .state('aulaDigital/temasByMateria', {
                parent: 'aulaDigital/materiasByNivel',
                url: '/{materiaUrl}',
                /* @ngInject */
                onEnter: function ($state, $stateParams, materias, materiaUrl) {
                    if (!$stateParams.materiaUrl && materias.length) {
                        $state.go("aulaDigital/temasByMateria", {
                            nivelUrl: $stateParams.nivelUrl,
                            materiaUrl: materiaUrl
                        });
                    }
                },
                views: {
                    'contentAula': {
                        template: '<tema-list-public temas="$resolve.temas"></tema-list-public>'
                    }
                },
                resolve: {
                    /* @ngInject */
                    materiaUrl: function ($stateParams, materias) {
                        if (!$stateParams.materiaUrl) {
                            if (materias.length) {
                                return materias[0].url;
                            }
                        } else return $stateParams.materiaUrl;
                    },
                    /* @ngInject */
                    temas: function ($stateParams, Tema, materiaUrl) {
                        if (!materiaUrl) {
                            return [];
                        }
                        return Tema.findByMateriaUrl({url: materiaUrl}).$promise.then(function (data) {
                            if (!data || !data.length) {
                                return [];
                            }
                            return data;
                        });
                    }
                }
            });
    }
})();
