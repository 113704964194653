(function () {
    'use strict';

    angular
        .module('app')
        .component('boletinListPublic', {
            templateUrl: 'app/components/aspg/boletin/public/boletin-list.public.html',
            controller: Controller,
            controllerAs: 'ctrl',
            bindings: {
                boletines: '<'
            }
        });

    /* @ngInject */
    function Controller($stateParams) {
        var vm = this;

        vm.page = $stateParams.page;
    }

})();
