(function (angular) {
    'use strict';

    angular
        .module('app')
        .controller('HeaderController', Controller);

    /* @ngInject */
    function Controller() {
        var vm = this;

    }

})(angular);
