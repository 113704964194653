(function () {
    'use strict';

    angular
        .module('app')
        .component('museoListPublic', {
            templateUrl: 'app/components/aspg/museos/public/museo/museo-list.public.html',
            controllerAs: 'ctrl',
            bindings: {
                museos: '<'
            }
        });
})();
