(function () {
    'use strict';

    /**
     * @ngdoc filter
     * @name app.filter:capitalize
     *
     * @returns {String} Devuelve un string con la primera letra de cada palabra en mayúscula
     */
    angular
        .module('app')
        .filter('capitalize', capitalize);

    function capitalize() {
        return capitalizeFilter;

        function capitalizeFilter(input) {
            if (input && input !== null) {
                input = input.toLowerCase();
                input = input.substring(0, 1).toUpperCase() + input.substring(1);
            }
            return input;
        }
    }
})();
