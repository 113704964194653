(function () {
    'use strict';

    /**
     * @ngdoc directive
     * @name app.directive:tienePermisos
     * @description
     * Directiva para ocultar elementos en caso de que no tengan los permisos suficientes.
     *
     * @param {String} permisoElemento Permiso que tiene el elemento para mostrarse.
     * @param {String} permisoConfig Permiso con el que se va a comparar (establecido de manera global en el archivo `config.js`).
     *
     * @requires app.service:Permisos
     */
    angular
        .module('app')
        .directive('tienePermisos', directive);

    /* @ngInject */
    function directive(Permisos) {
        return {
            restrict: 'AE',
            link: linkFunc,
            replace: false,
            scope: {
                permisoElemento: '<',
                permisoConfig: '<'
            }
        };

        function linkFunc(scope, element) {

            var setVisible = function () {
                element.removeClass('hidden');
            };
            var setHidden = function () {
                element.addClass('hidden');
            };
            console.log("Elem:", scope.permisoElemento);
            console.log("Conf:", scope.permisoConfig);

            var tienePermisos = Permisos.tienePermisos(scope.permisoElemento, scope.permisoConfig);
            console.log("Tiene:", tienePermisos);

            if (!tienePermisos) {
                setHidden();
            } else {
                setVisible();
            }
        }
    }
})();
