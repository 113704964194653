(function () {
    'use strict';

    angular
        .module('app')
        .component('salaDetailsPublic', {
            templateUrl: 'app/components/aspg/museos/public/salamuseo/sala-details.public.html',
            controller: Controller,
            controllerAs: 'ctrl',
            bindings: {
                sala: '<'
            }
        });

    /* @ngInject */
    function Controller(MuseosUtil) {
        var vm = this;

        // Imágen de la sala
        var urlBaseImagen = MuseosUtil.getRutaSalas(vm.sala.id);
        vm.imagen = MuseosUtil.obtenerImagen(urlBaseImagen, vm.sala.imagenPath);
    }

})();
