(function () {
    'use strict';

    angular
        .module('app')
        .config(stateConfig);

    /* @ngInject */
    function stateConfig($stateProvider) {
        $stateProvider.state('admin/changepassword', {
            parent: 'admin',
            url: '/admin/modificar-contrasena',
            data: {
                authorities: ['ROLE_AUTHENTICATED'],
                pageTitle: 'admin.changepassword.title'
            },
            views: {
                'content@': {
                    template: '<div class="row"><div class="col-md-8 col-md-offset-2">' +
                    '<change-password></change-password>' +
                    '</div></div>'
                }
            },
            ncyBreadcrumb: {
                label: 'admin.changepassword.title'
            },
            resolve: {
                /* @ngInject */
                translatePartialLoader: function ($translatePartialLoader) {
                    $translatePartialLoader.addPart('componentes/changepassword');
                    $translatePartialLoader.addPart('admin');
                }
            }
        });
    }
})();
