(function () {
    'use strict';

    angular
        .module('app')
        .component('webFormAdmin', {
            templateUrl: 'app/components/web/admin/web/web-form.admin.html',
            controller: Controller,
            controllerAs: 'ctrl',
            bindings: {
                item: '<',
                previousParams: '<',
                permisosCategoria: '<',
                categorias: '<'
            }
        });

    /* @ngInject */
    function Controller($rootScope, $timeout, $state, ModalService, WebUtil, CATEGORIA_WEB_FORM_ADMIN) {
        var vm = this;

        $timeout(function () {
            angular.element('.form-group:eq(0) input').focus();
        });

        vm.mode = $state.current.data.mode;
        vm.canSave = vm.mode === 'create' || vm.mode === 'edit';

        vm.urlBaseArchivo = WebUtil.getRutaWebs(vm.item.id);

        // Guardar formulario
        vm.save = function () {
            vm.item.$save().then(function () {
                ModalService.closeComponent(vm.item, 'admin/web/list', vm.previousParams);
            }).catch(function (response) {
                $rootScope.mostrarErrores(response, vm.form);
            });
        };

        vm.openCategoriaModal = function () {
            ModalService.openComponent('web.categoriaWeb.admin.create.title', CATEGORIA_WEB_FORM_ADMIN, {size: 'lg'}).result.then(function (result) {
                // Asociamos el nuevo elemento a la entidad
                vm.item.categoriaWeb = result;
                vm.categorias.push(result);
            });
        };

    }

})();
