(function () {
    'use strict';

    angular
        .module('app')
        .config(appConfig);

    /* @ngInject */
    function appConfig($translateProvider, cfpLoadingBarProvider, $compileProvider, $breadcrumbProvider, $qProvider) {


        // Configuración de la barra de carga
        cfpLoadingBarProvider.latencyThreshold = 150;
        cfpLoadingBarProvider.includeSpinner = false;

        // Configuración Breadcrumb
        $breadcrumbProvider.setOptions({
            templateUrl: 'app/components/breadcrumb/breadcrumb.html'
        });

        // TODO: disable debug data on prod profile to improve performance. Por ahora siempre en modo debug
        $compileProvider.debugInfoEnabled(true);

        $compileProvider.preAssignBindingsEnabled(true);
        $qProvider.errorOnUnhandledRejections(false);
    }
})();
