(function () {
    'use strict';

    angular.module('app')
        .factory('InfoTienda', Service);

    /* @ngInject */
    function Service($resource) {
        var urlBase = "api/tienda";
        return $resource(urlBase, {}, {
            getDestacados: {
                method: 'GET',
                url: urlBase + '/destacados',
                isArray: true,
                ignoreLoadingBar: true
            },
            getPromocion: {
                method: 'GET',
                url: urlBase + '/promocion',
                ignoreLoadingBar: true
            }
        });
    }

})();
