(function (angular) {
    'use strict';

    angular.module('app')
        .service('FacebookSdkService', Service);

    /* @ngInject */
    function Service(APIS) {
        return {
            config: config
        };

        function config() {
            window.fbAsyncInit = function () {
                FB.init({
                    appId: APIS.facebookId,
                    autoLogAppEvents: true,
                    xfbml: true,
                    version: APIS.facebookVersion
                });
                FB.AppEvents.logPageView();
            };

            (function (d, s, id) {
                var js, fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) {
                    return;
                }
                js = d.createElement(s);
                js.id = id;
                js.src = "//connect.facebook.net/en_US/sdk.js";
                fjs.parentNode.insertBefore(js, fjs);
            }(document, 'script', 'facebook-jssdk'));
        }
    }

})(angular);
