(function () {
    'use strict';

    /**
     * @ngdoc service
     * @name app.service:AlertService
     * @description
     * Servicio para trabajar con alertas.
     *
     */
    angular
        .module('app')
        .provider('AlertService', AlertService);

    function AlertService() {
        this.toast = false;
        /*jshint validthis: true */
        this.$get = getService;

        this.showAsToast = function (isToast) {
            this.toast = isToast;
        };

        getService.$inject = ['$timeout', '$sce', '$translate'];

        function getService($timeout, $sce, $translate) {
            var toast = this.toast,
                alertId = 0, // unique id for each alert. Starts from 0.
                alerts = [],
                timeout = 5000; // default timeout

            return {
                isToast: isToast,
                add: addAlert,
                closeAlert: closeAlert,
                closeAlertByIndex: closeAlertByIndex,
                clear: clear,
                clearPermanent: clearPermanent,
                get: get,
                success: success,
                error: error,
                info: info,
                warning: warning
            };

            /**
             * @ngdoc function
             * @name isToast
             * @methodOf app.service:AlertService
             * @returns {boolean} `true` if _floating_ alert, `false` otherwise
             */
            function isToast() {
                return toast;
            }

            /**
             * @ngdoc function
             * @name clear
             * @methodOf app.service:AlertService
             * @description
             * Removes all alerts
             */
            function clear() {
                alerts.splice(0, alerts.length);
            }

            /**
             * @ngdoc function
             * @name clearPermanent
             * @methodOf app.service:AlertService
             * @description
             * Each alert will close itself when if timeout is 0 (permanent alert)
             */
            function clearPermanent() {
                // Ocultamos alertas permanentes y las de error también
                alerts.forEach(function (alert, index) {
                    if (alert.timeout == 0) {
                        alerts.splice(index, 1);
                    }
                    if (alert.type === 'danger') {
                        alerts.splice(index, 1);
                    }
                });
            }

            /**
             * @ngdoc function
             * @name get
             * @methodOf app.service:AlertService
             * @returns {object} Alerts
             */
            function get() {
                return alerts;
            }

            /**
             * @ngdoc function
             * @name success
             * @methodOf app.service:AlertService
             * @description
             * Adds a success message
             *
             * @param {string} msg    Message key
             * @param {string} [params=null] JSON with the properties to be included in the message. Example: <br>
             *                 Message: `The cost is {{value}}` <br>
             *                 Param: `{value: 16}` <br>
             *                 Generated message: _The cost is 16_
             * @param {number} [time=null] Time in ms that the message will be displayed, `null` means permanent.
             * @param {string} [position=null] Message location. Available only when _toast_ mode is set.
             */
            function success(msg, params, time, position) {
                return this.add({
                    type: 'success',
                    msg: msg,
                    params: params,
                    timeout: getTimeout(time),
                    toast: toast,
                    position: position
                });
            }

            /**
             * @ngdoc function
             * @name error
             * @methodOf app.service:AlertService
             * @description
             * Adds an error message
             *
             * @param {string} msg    Message key
             * @param {string} [params=null] JSON with the properties to be included in the message. Example: <br>
             *                 Message: `The cost is {{value}}` <br>
             *                 Param: `{value: 16}` <br>
             *                 Generated message: _The cost is 16_
             * @param {number} [time=null] Time in ms that the message will be displayed, `null` means permanent.
             * @param {string} [position=null] Message location. Available only when _toast_ mode is set.
             */
            function error(msg, params, time, position) {
                return this.add({
                    type: 'danger',
                    msg: msg,
                    params: params,
                    timeout: getTimeout(time),
                    toast: toast,
                    position: position
                });
            }

            /**
             * @ngdoc function
             * @name warning
             * @methodOf app.service:AlertService
             * @description
             * Adds a warning message
             *
             * @param {string} msg    Message key
             * @param {string} [params=null] JSON with the properties to be included in the message. Example: <br>
             *                 Message: `The cost is {{value}}` <br>
             *                 Param: `{value: 16}` <br>
             *                 Generated message: _The cost is 16_
             * @param {number} [time=null] Time in ms that the message will be displayed, `null` means permanent.
             * @param {string} [position=null] Message location. Available only when _toast_ mode is set.
             */
            function warning(msg, params, time, position) {
                return this.add({
                    type: 'warning',
                    msg: msg,
                    params: params,
                    timeout: getTimeout(time),
                    toast: toast,
                    position: position
                });
            }

            /**
             * @ngdoc function
             * @name info
             * @methodOf app.service:AlertService
             * @description
             * Adds an info message
             *
             * @param {string} msg    Message key
             * @param {string} [params=null] JSON with the properties to be included in the message. Example: <br>
             *                 Message: `The cost is {{value}}` <br>
             *                 Param: `{value: 16}` <br>
             *                 Generated message: _The cost is 16_
             * @param {number} [time=null] Time in ms that the message will be displayed, `null` means permanent.
             * @param {string} [position=null] Message location. Available only when _toast_ mode is set.
             */
            function info(msg, params, time, position) {
                return this.add({
                    type: 'info',
                    msg: msg,
                    params: params,
                    timeout: getTimeout(time),
                    toast: toast,
                    position: position
                });
            }

            /**
             * @ngdoc function
             * @name success
             * @methodOf app.service:AlertService
             * @description
             * Adds a success message
             *
             * @param {string} msg    Message key
             * @param {string} [params=null] JSON with the properties to be included in the message. Example: <br>
             *                 Message: `The cost is {{value}}` <br>
             *                 Param: `{value: 16}` <br>
             *                 Generated message: _The cost is 16_
             * @param {number} [time=null] Time in ms that the message will be displayed, `null` means permanent.
             * @param {string} [position=null] Message location. Available only when _toast_ mode is set.
             */
            function addAlert(alertOptions, extAlerts) {
                alertOptions.alertId = alertId++;
                alertOptions.msg = $translate.instant(alertOptions.msg, alertOptions.params);
                var that = this;
                var alert = factory(alertOptions);
                if (alertOptions.timeout && alertOptions.timeout > 0) {
                    $timeout(function () {
                        that.closeAlert(alertOptions.alertId, extAlerts);
                    }, alertOptions.timeout);
                }
                return alert;
            }

            /**
             * @ngdoc function
             * @name closeAlert
             * @methodOf app.service:AlertService
             * @description
             * Removes the alert with specified id from the alerts array
             * @param {number} id Alert id
             * @param {object} [extAlerts=null] Array of alerts
             */
            function closeAlert(id, extAlerts) {
                var thisAlerts = extAlerts ? extAlerts : alerts;
                return closeAlertByIndex(thisAlerts.map(function (e) {
                    return e.id;
                }).indexOf(id), thisAlerts);
            }

            /**
             * @ngdoc function
             * @name closeAlertByIndex
             * @methodOf app.service:AlertService
             * @description
             * Removes the alert at index position
             * @param {number} index Alert position in the alerts array
             * @param {object} thisAlerts Array of alerts
             */
            function closeAlertByIndex(index, thisAlerts) {
                return thisAlerts.splice(index, 1);
            }

            // -----------------
            // Private functions
            // -----------------

            function getTimeout(time) {
                if (time == null) return timeout;
                return time;
            }

            function factory(alertOptions) {
                if (!alertOptions.params) {
                    alertOptions.params = {};
                }
                var alert = {
                    type: alertOptions.type,
                    msg: $sce.trustAsHtml(alertOptions.msg),
                    id: alertOptions.alertId,
                    timeout: alertOptions.timeout,
                    toast: alertOptions.params.toast ? alertOptions.params.toast : alertOptions.toast,
                    position: alertOptions.params.position ? alertOptions.params.position : 'bottom right',
                    scoped: alertOptions.scoped,
                    close: function (alerts) {
                        return closeAlert(this.id, alerts);
                    }
                };
                if (!alert.scoped) {
                    alerts.push(alert);
                }
                return alert;
            }
        }
    }
})();
