(function (angular) {
    'use strict';

    /**
     * @ngdoc component
     * @name app.component:loginAdmin
     * @description
     * Componente que muestra un formulario para acceder a la administración.
     *
     * Es específico de la adiministración porque a la administración se suele acceder con un nombre de usuario, y no con un email.
     *
     */
    angular
        .module('app')
        .component('loginAdmin', {
            templateUrl: 'app/components/loginadmin/login-admin.html',
            controllerAs: 'ctrl',
            /* @ngInject */
            controller: function ($rootScope, $state, $timeout, Auth, Principal, ADMIN_PRINCIPAL) {
                var vm = this;

                vm.user = {};
                vm.rememberMe = false;

                Principal.identity().then(function (data) {
                    if (data) {
                        $state.go(ADMIN_PRINCIPAL);
                    }
                });

                $timeout(function () {
                    angular.element('.form-group:eq(0)>input').focus();
                });

                vm.login = function () {
                    Auth.login({
                        username: vm.username,
                        password: vm.password,
                        rememberMe: vm.rememberMe
                    }).then(function () {
                        $state.go(ADMIN_PRINCIPAL);
                    }).catch(function (response) {
                        $rootScope.mostrarErrores(response, vm.form);
                    });

                }
            }
        });
})(angular);
