(function () {
    'use strict';

    angular
        .module('app')
        .config(stateConfig);

    /* @ngInject */
    function stateConfig($stateProvider, STATE_HELPER, CATEGORIA_WEB_FORM_ADMIN, COMPONENTES, PERMISOS) {
        var configCategorias = COMPONENTES.webs.categoriasAdmin;
        if (configCategorias === 'none') return;

        var config = CATEGORIA_WEB_FORM_ADMIN();
        var base = "admin/categoriaWeb";
        var params = {
            base: base,
            baseUrl: "/" + base,
            translateBase: "web.categoriaWeb.admin.",
            templateList: '<categoria-web-list-admin permisos="$resolve.permisos"></categoria-web-list-admin>',
            templateForm: config.component
        };

        var state = STATE_HELPER(params);
        // Estado Padre
        angular.merge(state.parent, {
            data: {
                authorities: ['ROLE_ADMIN']
            },
            resolve: {
                /* @ngInject */
                translatePartialLoader: function ($translatePartialLoader) {
                    $translatePartialLoader.addPart('componentes/webs');
                }
            }
        });
        // Listar
        angular.merge(state.list, {
            resolve: {
                /* @ngInject */
                permisos: function () {
                    return configCategorias;
                }
            }
        });
        // Crear
        angular.merge(state.create, {
            resolve: config.resolve
        });
        // Editar
        angular.merge(state.edit, {
            resolve: angular.merge(config.resolve, {
                /* @ngInject */
                item: function (CategoriaWeb, $stateParams) {
                    return CategoriaWeb.get({id: $stateParams.id}).$promise;
                }
            })
        });

        // Se definen los estados

        if (PERMISOS('list', configCategorias)) {
            $stateProvider
                .state(params.base, state.parent)
                .state(state.list.name, state.list);
        }
        if (PERMISOS('create', configCategorias)) {
            $stateProvider
                .state(state.create.name, state.create);
        }
        if (PERMISOS('edit', configCategorias)) {
            $stateProvider
                .state(state.edit.name, state.edit);
        }
    }
})();
