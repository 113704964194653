(function () {
    'use strict';

    angular
        .module('app')
        .config(stateConfig);

    /* @ngInject */
    function stateConfig($stateProvider) {
        $stateProvider
            .state('museos', {
                parent: 'public',
                url: '/museos',
                redirectTo: 'museos/lista',
                data: {
                    authorities: [],
                    pageTitle: 'museos.titulo'
                },
                resolve: {
                    /* @ngInject */
                    translatePartialLoader: function ($translatePartialLoader) {
                        $translatePartialLoader.addPart('componentes/museos');
                    },
                    /* @ngInject */
                    museos: function (Museo) {
                        return Museo.findPublic({sortProperty: 'orden'}).$promise.then(function (data) {
                            return data;
                        });
                    }
                }
            })
            .state('museos/lista', {
                parent: 'museos',
                url: '',
                views: {
                    'content@': {
                        template: '<h1 translate="museos.titulo"></h1>' +
                        '<museo-list-public museos="$resolve.museos"></museo-list-public>'
                    }
                }
            })
            .state('museos/museo', {
                parent: 'museos',
                url: '/{url}',
                redirectTo: 'museos/museo/salas',
                params: {museo: null},
                views: {
                    'content@': {
                        templateUrl: 'app/components/aspg/museos/public/museos-template.public.html',
                        controllerAs: 'ctrl'
                    }
                },
                resolve: {
                    /* @ngInject */
                    url: function ($stateParams) {
                        return $stateParams.url;
                    },
                    /* @ngInject */
                    museo: function ($stateParams, Museo, url) {
                        if ($stateParams.museo !== null)
                            return $stateParams.museo;

                        return Museo.getByUrl({url: url});
                    }
                }
            })
            .state('museos/museo/salas', {
                parent: 'museos/museo',
                url: '',
                views: {
                    'seccionesMuseo': {
                        template: '<sala-list-public salas="$resolve.salas"></sala-list-public>'
                    }
                },
                resolve: {
                    /* @ngInject */
                    salas: function ($stateParams, SalaMuseo) {
                        return SalaMuseo.findByMuseoUrl({url: $stateParams.url}).$promise.then(function (data) {
                            if (!data || !data.length) {
                                return [];
                            }
                            return data;
                        });
                    }
                }
            })
            .state('museos/museo/sala', {
                parent: 'museos/museo',
                url: '/{id}',
                params: {sala: null},
                views: {
                    'seccionesMuseo': {
                        template: '<obra-list-public obras="$resolve.obras" sala="$resolve.sala"></obra-list-public>'
                    }
                },
                resolve: {
                    /* @ngInject */
                    obras: function ($stateParams, ObraMuseo) {
                        return ObraMuseo.findBySala({id: $stateParams.id}).$promise.then(function (data) {
                            return data;
                        });
                    },
                    sala: function ($stateParams, SalaMuseo) {
                        if ($stateParams.sala !== null) {
                            return $stateParams.sala;
                        }
                        else {
                            return SalaMuseo.query({id: $stateParams.id}).$promise.then(function (data) {
                                return data;
                            });
                        }
                    }
                }
            });
    }
})();
