(function () {
    'use strict';

    angular.module('app')
        .factory('UsuarioAspg', Service);

    /* @ngInject */
    function Service(ResourceHelper) {
        var entidad = "usuario-aspg";
        return ResourceHelper.getResource(entidad, {}, {
            recover: {
                method: 'GET',
                url: 'api/' + entidad + '/recover',
                params: {
                    id: null
                }
            },
            getAutenticado: {
                method: 'GET',
                url: 'api/' + entidad + '/getAutenticado',
                params: {
                    username: null
                }
            },
            editar: {
                method: 'POST',
                url: 'api/' + entidad + '/edit'
            },
            changePassword: {
                method: 'POST',
                url: 'api/' + entidad + '/change_password'
            }
        });
    }

})();
