(function (angular) {
    'use strict';

    angular
        .module('app')
        .component('cursos', {
            templateUrl: 'app/components/aspg/cursos/cursos.html',
            controller: cursosController,
            controllerAs: 'ctrl'
        });

    /* @ngInject */
    function cursosController(Curso) {
        var vm = this;

        Curso.getUltimos().$promise.then(function (data) {
            vm.cursos = data;
        }).catch(function () {
            vm.cursos = [];
        });
    }
})(angular);
