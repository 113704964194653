(function () {
    'use strict';
    // DO NOT EDIT THIS FILE, EDIT THE GULP TASK NGCONSTANT SETTINGS INSTEAD WHICH GENERATES THIS FILE
    angular
        .module('app')
        .constant('DEBUG_INFO_ENABLED', false)
        .constant('CONTEXT', "")
        .constant('LANGUAGES', {
	"default": "gl",
	"list": [
		"gl"
	]
})
        .constant('APIS', {
	"facebookId": "232480380858801",
	"facebookSecret": "72a301c8455c8fcbf0f7c127ba5a45c3",
	"facebookVersion": "v3.0",
	"facebookToken": "EAACEdEose0cBAH1eCaPD16JEdROIGVB1ZCFz66lILZB7gpvzHvKiMoO0OtirEscpRZCMv9W4QZBOckmL4AgBIzXdMg5S8RnpvEBWgYsxF50ZAuCnNAZBJkV0do88hmC3Jy5qlwZBWw1i4AmfozHbO2FTQSL97Rj90OGJKmtxKE8eMu56lJd6radUlb1x7eRMPjHh00aJTMJBD6rwgiArslf",
	"twitterKey": "lH6rO4oZwTWsqauTwBWSqRybP",
	"twitterSecret": "aHTWYgVQbai8GtvvJB6Go7enLQmp8hl1Br2EmSrAfIOdPFo8oJ",
	"googleMaps": "AIzaSyCa18a1ro6IL4AObCW4EqcxdTACWZdw_tg",
	"recaptcha": "6LfODVwUAAAAAMrSfGWvNMWD49IdvLJDonGyQK_r"
})
        .constant('COMPONENTES', {
	"aulaDigital": {
		"nivelesAdmin": "list"
	},
	"contacto": {
		"captcha": "all"
	},
	"feedsRss": {
		"categoriasAdmin": "edit"
	},
	"novedades": {
		"categoriasAdmin": "none",
		"categoriasPublic": "none"
	},
	"webs": {
		"categoriasAdmin": "all"
	}
})
;
})();
