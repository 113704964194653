(function () {
    'use strict';

    angular
        .module('app')
        .component('obraListPublic', {
            templateUrl: 'app/components/aspg/museos/public/obramuseo/obra-list.public.html',
            controller: Controller,
            controllerAs: 'ctrl',
            bindings: {
                obras: '<',
                sala: '<'
            }
        });

    /* @ngInject */
    function Controller(MuseosUtil, $window) {
        var vm = this;
        vm.imagenes = MuseosUtil.obtenerImagenesNgGallery(vm.obras);

        vm.irARecepcion = function(){
            $window.history.back();
        };
    }
})();
