(function () {
    'use strict';

    /**
     * @ngdoc object
     * @name app.object:public_constants
     *
     * @description
     * Constantes para la parte pública:
     *
     * - NG_TABLE_DEFAULT_PARAMS_PUBLIC: `{size: 20}`
     * - NG_LIST_DEFAULT_PARAMS_PUBLIC: `{size: 20}`
     *
     */
    angular
        .module('app')
        // Nº de elementos por página en las tablas
        .constant('NG_TABLE_DEFAULT_PARAMS_PUBLIC', {size: 20})
        .constant('NG_LIST_DEFAULT_PARAMS_PUBLIC', {size: 10})
    ;
})();
