(function () {
    'use strict';

    angular.module('app')
        .factory('MuseosUtil', Service);

    /* @ngInject */
    function Service(NgImageGalleryHelper) {
        return {
            getRutaMuseosBase: getRutaMuseosBase,
            getRutaMuseos: getRutaMuseos,
            getRutaSalas: getRutaSalas,
            getRutaObras: getRutaObras,
            obtenerImagen: obtenerImagen,
            obtenerImagenesNgGallery: obtenerImagenesNgGallery,
            obtenerThumbnail: getUrlThumbnail
        };

        function getRutaMuseosBase() {
            return '/upload/museos/';
        }

        function getRutaMuseos(id) {
            return getRutaMuseosBase() + 'museos/' + id + '/';
        }

        function getRutaSalas(id) {
            return getRutaMuseosBase() + 'salas/' + id + '/';
        }

        function getRutaObras(id) {
            return getRutaMuseosBase() + 'obras/' + id + '/';
        }

        // Devuelve el elemento 'url()' con la ruta de la imagen si esta es distinta de null o
        // con la ruta de la imágen por defecto en caso contrario
        function obtenerImagen(urlBaseImagen, imagenPath) {
            var tieneImagen = imagenPath !== null;

            if (tieneImagen) {
                return 'url("' + getUrlThumbnail(urlBaseImagen, imagenPath) + '")';
            } else {
                return 'url("assets/images/static/museo/sin-imagen.png")';
            }
        }

        function obtenerImagenesNgGallery(items) {
            var imagenesGallery = [];

            items.forEach(function (item) {
                var urlImagen = getUrlImagen(getRutaObras(item.id), item.imagenPath);
                var urlThumbnail = getUrlThumbnail(getRutaObras(item.id), item.imagenPath);
                var titulo = "<h1>" + item.titulo + "</h1>";
                if (item.descripcion !== null) {
                    titulo += "<p>" + item.descripcion + "</p>";
                }

                imagenesGallery.push(NgImageGalleryHelper.toNgImageGalleryImagen(item.id, urlImagen, urlThumbnail, titulo));
            });

            return imagenesGallery;
        }

        function getUrlImagen(urlBaseImagen, pathImagen) {
            return urlBaseImagen + pathImagen;
        }

        function getUrlThumbnail(urlBaseImagen, pathImagen) {
            return urlBaseImagen + 'n_' + pathImagen;
        }
    }

})();
