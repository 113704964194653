(function () {
    'use strict';

    angular
        .module('app')
        .component('novedadListPublic', {
            templateUrl: 'app/components/novedades/public/novedad-list.public.html',
            controller: Controller,
            controllerAs: 'ctrl',
            bindings: {
                categoriasNovedad: '<',
                novedades: '<',
                etiqueta: '<'
            }
        });

    /* @ngInject */
    function Controller($state, $stateParams) {
        var vm = this;

        vm.page = $stateParams.page;

        vm.search = function () {
            vm.filtro.categoriaNovedadId = vm.filtro.categoriaNovedad !== null ? vm.filtro.categoriaNovedad.id : null;
            $state.go($state.current, $state.params);
        };

    }

})();
