(function () {
    'use strict';

    angular
        .module('app')
        .config(stateConfig);

    /* @ngInject */
    function stateConfig($stateProvider) {
        $stateProvider.state('admin/managerLogin', {
            parent: 'admin',
            url: '/admin/administrar-webs',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'managerLogin.titulo'
            },
            views: {
                'content@': {
                    templateUrl: 'app/components/aspg/managerlogin/manager-login.admin.html'
                }
            },
            ncyBreadcrumb: {
                label: 'managerLogin.titulo'
            },
            resolve: {
                /* @ngInject */
                translatePartialLoader: function ($translatePartialLoader) {
                    $translatePartialLoader.addPart('componentes/managerLogin');
                },
                /* @ngInject */
                datos: function (ManagerLogin) {
                    return ManagerLogin.get().$promise;
                }
            }
        });
    }
})();
