(function (angular) {
    'use strict';

    /**
     * @ngdoc component
     * @name app.component:socialShare
     * @description
     * Compartir contenido en redes sociales.
     *
     * Para añadir Google+ habría que añader etiquetas <meta> a la página. El crawler de Google sí interpreta javascript (no como el de Twitter y Facebook).
     *
     * Para Facebook incluimos el SDK. La carga se hace en el archivo `assets/js/facebook-sdk.service.js`.
     *
     * Referencia: {@link https://github.com/720kb/angular-socialshare}
     *
     * @param {String} url URL que se va a compartir. Si es relativa (empieza por "/") se le concatenará la url base.
     * @param {Boolean} [mini=false] Booleano para saber si mostrar botones más grandes o pequeños.
     * @param {Boolean} [textos=false] Booleano para saber si se muestran los nombres de las redes sociles o sólo los iconos.
     * @param {Boolean} [twitter=false] Compartir en Twitter.
     * @param {Boolean} [facebook=false] Compartir en Facebook.
     * @param {String} titulo Título que se mostrará en Facebook. Obligatorio.
     * @param {String} descripcion Descripción que se mostrará en Facebook.
     * @param {String} [imagen='/assets/images/logo_img.png'] Imagen que se mostrará en facebook. Si no se indica ninguna, se usará el logo de la aplicación.
     * @param {String} tweet Texto del tweet.
     * @param {String} twHashtags Hashtags que se añadirá al tweet. (Creo que hay un problema cuando se quiere añadir un número o símbolos)
     * @param {String} twVia Nombre de la cuenta (vía @cuenta)
     *
     */
    angular
        .module('app')
        .component('socialShare', {
            bindings: {
                url: '@',
                mini: '<',
                textos: '<',
                twitter: '<',
                facebook: '<',
                titulo: '@',
                descripcion: '@',
                imagen: '@',
                tweet: '@',
                twHashtags: '@',
                twVia: '@'
            },
            templateUrl: 'app/components/socialshare/social-share.html',
            /* @ngInject */
            controller: function ($scope, $location, $translate, RutasService, FacebookSdkService) {
                var vm = this;

                // Descargamos el SDK de Facebook
                FacebookSdkService.config();

                if (!vm.imagen) {
                    // La imagen por defecto debería ser cuadrada para que se muestre correctamente
                    vm.imagenUrl = RutasService.getBaseUrl() + "/assets/images/static/aspg_logo_cuadrado.png";
                } else {
                    vm.imagenUrl = RutasService.getBaseUrl() + vm.imagen;
                }

                if (vm.url.indexOf("/") === 0) {
                    vm.fullUrl = RutasService.getBaseUrl() + vm.url;
                } else {
                    vm.fullUrl = vm.url;
                }

                vm.compartirEnFacebook = function () {
                    var datos = {
                        'og:title': vm.titulo,
                        'og:url': vm.fullUrl
                    };
                    if (vm.descripcion) {
                        if (/<[a-z][\s\S]*>/i.test(vm.descripcion)) {
                            datos["og:description"] = jQuery(vm.descripcion).text();
                        } else {
                            datos["og:description"] = vm.descripcion;
                        }
                    }
                    if (vm.imagenUrl) {
                        datos["og:image"] = vm.imagenUrl;
                    }

                    FB.ui({
                        method: 'share_open_graph',
                        action_type: 'og.shares',
                        action_properties: JSON.stringify({
                            object: datos
                        })
                    }, function (response) {
                        console.log("Facebook response: ", response);
                    });
                }

            },
            controllerAs: 'ctrl'
        });

})(angular);
