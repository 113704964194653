(function () {
    'use strict';

    angular
        .module('app')
        /* @ngInject */
        .constant('ADMIN_FORM_ADMIN', function (modal) {
            var resolve = "$resolve.";
            if (modal) {
                resolve = "ctrl.resolve."
            }
            return {
                component: '<admin-form-admin ' +
                'item="' + resolve + 'item" ' +
                'previous-params="' + resolve + 'previousParams" ' +
                'roles="' + resolve + 'roles"' +
                '></admin-form-admin>',
                resolve: {
                    /* @ngInject */
                    item: function (Administrador) {
                        return new Administrador();
                    },
                    /* @ngInject */
                    roles: function (EnumService) {
                        return EnumService.get("rol-admin");
                    }
                }
            }
        });
})();
