(function (angular) {
    'use strict';

    /**
     * @ngdoc component
     * @name app.component:rss
     * @description
     * Componente que muestra una lista de Feeds RSS agrupados por categoría.
     *
     * @param {Number} categoriaId ID de la categoría de la que se obtendrán los feedsRSS.
     *
     */
    angular
        .module('app')
        .component('rss', {
            templateUrl: 'app/components/feedrss/rss/rss.html',
            controller: Controller,
            controllerAs: 'ctrl',
            bindings: {
                categoriaId: '@',
                imagen: '@'
            }
        });

    /* @ngInject */
    function Controller(Rss, CategoriaRss, $translatePartialLoader) {
        var vm = this;
        vm.loading = true;

        $translatePartialLoader.addPart('componentes/feedsRss');

        CategoriaRss.get({id: vm.categoriaId}).$promise.then(function (data) {
            vm.categoria = data;
        });

        Rss.query({categoriaId: vm.categoriaId}).$promise.then(function (data) {
            vm.loading = false;
            vm.entradas = data;
        });
    }

})(angular);
