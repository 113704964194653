(function () {
    'use strict';

    /**
     * @ngdoc filter
     * @name app.filter:unsafe
     * @description
     * Cuando se utiliza la directiva `ngBindHtml`, al HTML se le eliminan las etiquetas _style_.
     * Añadiendo el filtro `unsafe`, el HTML se muestra tal cual es.
     *
     * @returns {String} Se marca el HTML como que es de confianza, por lo que no sufre modificaciones.
     */
    angular
        .module('app')
        .filter('unsafe', filter);

    /* @ngInject */
    function filter($sce) {
        return filter;

        function filter(input) {
            return $sce.trustAsHtml(input);
        }
    }
})();
