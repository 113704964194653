(function () {
    'use strict';

    angular
        .module('app')
        /* @ngInject */
        .constant('TEMA_FORM_ADMIN', function (modal) {
            var resolve = "$resolve.";
            if (modal) {
                resolve = "ctrl.resolve."
            }
            return {
                component: '<tema-form-admin ' +
                'item="' + resolve + 'item" ' +
                'previous-params="' + resolve + 'previousParams"' +
                'materias="' + resolve + 'materias" ' +
                '></tema-form-admin>',
                resolve: {
                    /* @ngInject */
                    item: function (Tema) {
                        return new Tema();
                    },
                    /* @ngInject */
                    materias: function ($stateParams, Materia) {
                        return Materia.query({
                            padreId: $stateParams.nivelid,
                            sortProperty: 'orden'
                        }).$promise.then(function (data) {
                            return data.content;
                        });
                    }
                }
            }
        });
})();
