(function () {
    'use strict';

    angular
        .module('app')
        .component('museoInfoPublic', {
            templateUrl: 'app/components/aspg/museos/public/museo/museo-info.public.html',
            controller: Controller,
            controllerAs: 'ctrl',
            bindings: {
                museo: '<'
            }
        });

    /* @ngInject */
    function Controller(MuseosUtil, ModalService) {
        var vm = this;

        // Imágen del museo
        var urlBaseImagen = MuseosUtil.getRutaMuseos(vm.museo.id);
        vm.imagen = MuseosUtil.obtenerThumbnail(urlBaseImagen, vm.museo.imagenPath);

        vm.abrirImagen = function () {
            ModalService.openImagen(vm.imagen);
        };
    }

})();
