(function (angular) {
    'use strict';

    /**
     * @ngdoc directive
     * @name app.directive:submenu
     * @description
     * Directiva que construye un submenú a partir de un JSON.
     *
     * @requires app.directive:menuItem
     *
     */
    angular
        .module('app')
        .directive('submenu', submenuDirective);

    submenuDirective.$inject = [];

    function submenuDirective() {
        return {
            restrict: 'E',
            replace: true,
            scope: {
                menu: '=',
                onClick: '&'
            },
            templateUrl: 'app/components/menu/submenu.html'
        };
    }
})(angular);
