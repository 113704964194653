(function () {
    'use strict';

    /**
     * @ngdoc component
     * @name app.component:date
     * @description
     * Componente para mostrar una fecha en un formato concreto.
     *
     * @param {Object} model Valor de la fecha a mostrar
     * @param {String} [format=dd/MM/yyyy, HH:mm] Formato que tendrá la fecha
     * @param {Boolean} [fecha=false] Booleano para indicar si se tiene que incluir la fecha
     * @param {Boolean} [hora=false] Booleano para indicar si se tiene que incluir la hora
     *
     */
    angular
        .module('app')
        .component('date', {
            template: '{{ctrl.result | date:ctrl.format}}',
            bindings: {
                model: '<', // required
                format: '@',
                fecha: '<',
                hora: '<'
            },
            controller: Controller,
            controllerAs: 'ctrl'
        });

    /* @ngInject */
    function Controller($scope) {
        var vm = this;
        if (!vm.format) {
            if (vm.fecha) {
                vm.format = 'dd/MM/yyyy';
            } else {
                if (vm.hora) {
                    vm.format = 'HH:mm';
                } else {
                    vm.format = 'dd/MM/yyyy, HH:mm';
                }
            }
        }

        function init() {
            // Si recibimos la fecha del servidor, convertimos segundos a milisegundos
            if (angular.isNumber(vm.model)) {
                vm.result = vm.model * 1000;
            }
        }

        $scope.$watch(function () {
            return vm.model;
        }, function () {
            init();
        });
    }

})();
