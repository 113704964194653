(function () {
    'use strict';

    angular
        .module('app')
        .component('recursoEducativoFormAdmin', {
            templateUrl: 'app/components/auladigital/admin/recursoeducativo/recurso-educativo-form.admin.html',
            controller: Controller,
            controllerAs: 'ctrl',
            bindings: {
                item: '<',
                previousParams: '<',
                temas: '<'
            }
        });

    /* @ngInject */
    function Controller($rootScope, $timeout, $state, $stateParams, ModalService, AulaDigitalUtil) {
        var vm = this;

        $timeout(function () {
            angular.element('.form-group:eq(0) input').focus();
        });

        vm.mode = $state.current.data.mode;
        vm.canSave = vm.mode === 'create' || vm.mode === 'edit';

        function init() {
            vm.urlBaseArchivo = AulaDigitalUtil.getRutaRecursos(vm.item.id);

            if (!vm.item.tema) {
                vm.item.tema = {id: $stateParams.temaid};
            }
        }

        // Guardar formulario
        vm.save = function () {
            vm.item.$save().then(function () {
                ModalService.closeComponent(vm.item, 'admin/aulaDigital/recursoEducativo/list', vm.previousParams);
            }).catch(function (response) {
                $rootScope.mostrarErrores(response, vm.form);
            });
        };

        init();

    }

})();
