(function () {
    'use strict';

    angular
        .module('app')
        .component('boletinDetailsPublic', {
            templateUrl: 'app/components/aspg/boletin/public/boletin-details.public.html',
            controller: Controller,
            controllerAs: 'ctrl',
            bindings: {
                boletin: '<'
            }
        });

    /* @ngInject */
    function Controller(BoletinUtil, ModalService) {
        var vm = this;

        vm.urlBaseImagen = BoletinUtil.getRutaImagen(vm.boletin.id);
        vm.urlBaseArchivo = BoletinUtil.getRutaArchivo(vm.boletin.id);

        vm.fecha = Date.parse(vm.boletin.mes + '/01/' + vm.boletin.ano);

        if (vm.boletin.imagenPath !== null) {
            vm.imagen = 'url("' + vm.urlBaseImagen + 'n_' + vm.boletin.imagenPath + '")';
        } else {
            vm.imagen = 'url("assets/images/boletines/boletin-electronico.jpg")';
        }

        vm.abrirImagen = function () {
            if (vm.boletin.imagenPath !== null) {
                ModalService.openImagen(vm.urlBaseImagen + vm.boletin.imagenPath);
            }
        };

        vm.abrirSeccion = function (seccion) {
            ModalService.open({
                templateUrl: 'app/components/aspg/boletin/public/seccion-boletin-details.public.html',
                size: 'lg',
                controllerAs: 'ctrl',
                /* @ngInject */
                controller: function (ModalService, boletin, seccion, fecha) {
                    var vm = this;
                    vm.boletin = boletin;
                    vm.seccion = seccion;
                    vm.fecha = fecha;
                    vm.dismiss = function () {
                        ModalService.dismiss();
                    };
                },
                resolve: {
                    boletin: function () {
                        return vm.boletin;
                    },
                    seccion: function () {
                        return seccion;
                    },
                    fecha: function () {
                        return vm.fecha;
                    }
                }
            });
        };

    }

})();
