(function (angular) {
    'use strict';

    angular
        .module('app')
        .component('recuperarFinish', {
            templateUrl: 'app/components/aspg/usuarios/recuperar/recuperar-finish.html',
            controllerAs: 'ctrl',
            /* @ngInject */
            controller: function ($timeout, Auth, $stateParams) {
                var vm = this;

                $timeout(function () {
                    angular.element('.form-group:eq(0)>input').focus();
                });

                vm.save = function () {
                    if (vm.newPassword === vm.confirmPassword) {
                        Auth.resetPasswordFinish({
                            email: $stateParams.email,
                            key: $stateParams.key,
                            newPassword: vm.newPassword
                        }).then(function () {
                            vm.success = true;
                        });
                    }
                };
            }
        });
})(angular);
