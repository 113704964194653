(function (angular) {
    'use strict';

    angular
        .module('app')
        .component('xogos', {
            templateUrl: 'app/components/aspg/enxogo/xogos.html',
            controllerAs: 'ctrl',
            bindings: {
                xogos: '<'
            }
        });

})(angular);
