(function () {
    'use strict';

    angular
        .module('app')
        .config(stateConfig);

    /* @ngInject */
    function stateConfig($stateProvider) {
        $stateProvider
            .state('web', {
                parent: 'public',
                url: '/web',
                redirectTo: 'web/categorias',
                data: {
                    authorities: [],
                    pageTitle: 'web.titulo'
                },
                resolve: {
                    /* @ngInject */
                    translatePartialLoader: function ($translatePartialLoader) {
                        $translatePartialLoader.addPart('componentes/webs');
                    },
                    /* @ngInject */
                    categorias: function (CategoriaWeb) {
                        return CategoriaWeb.query({sortProperty: 'orden'}).$promise.then(function (data) {
                            return data.content;
                        });
                    }
                }
            })
            .state('web/categorias', {
                parent: 'web',
                url: '',
                views: {
                    'content@': {
                        template: '<h1 translate="web.titulo"></h1>' +
                        '<p translate="web.intro1"></p><p translate="web.intro2"></p>' +
                        '<categoria-web-list-public categorias="$resolve.categorias"></categoria-web-list-public>'
                    }
                }
            })
            .state('web/websByCategoria', {
                parent: 'web',
                url: '/{categoriaUrl}',
                views: {
                    'content@': {
                        templateUrl: 'app/components/web/public/web-list-template.public.html',
                        controllerAs: 'ctrl'
                    }
                },
                resolve: {
                    /* @ngInject */
                    webs: function ($stateParams, Web) {
                        return Web.findByCategoriaUrl({categoriaUrl: $stateParams.categoriaUrl}).$promise.then(function (data) {
                            return data;
                        });
                    }
                }
            });
    }
})();
