(function () {
    'use strict';

    angular.module('app')
        .factory('Web', Service);

    /* @ngInject */
    function Service(ResourceHelper) {
        var entidad = "web";
        return ResourceHelper.getResource(entidad, {}, {
            'findByCategoriaUrl': {
                method: 'GET',
                url: 'api/' + entidad + '/findByCategoriaUrl',
                params: {
                    categoriaUrl: null
                },
                isArray: true
            }
        });
    }

})();
