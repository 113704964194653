(function () {
    'use strict';

    angular
        .module('app')
        .config(stateConfig);

    /* @ngInject */
    function stateConfig($stateProvider) {
        $stateProvider
            .state('enXogo', {
                parent: 'public',
                url: '/en-xogo',
                redirectTo: 'enXogo/login',
                data: {
                    pageTitle: 'enXogo.titulo'
                },
                resolve: {
                    /* @ngInject */
                    translatePartialLoader: function ($translatePartialLoader) {
                        $translatePartialLoader.addPart('componentes/enXogo');
                    }
                }
            })
            .state('enXogo/login', {
                parent: 'enXogo',
                url: '/entrar',
                data: {
                    authorities: ['ROLE_ANONYMOUS']
                },
                views: {
                    'content@': {
                        template: '<h1 translate="enXogo.titulo"></h1>' +
                        '<login></login>'
                    }
                }
            })
            .state('enXogo/recuperar/init', {
                parent: 'enXogo',
                url: '/recuperar-contrasena',
                data: {
                    authorities: ['ROLE_ANONYMOUS'],
                    pageTitle: 'recuperar.title'
                },
                views: {
                    'content@': {
                        template: "<recuperar-init></recuperar-init>"
                    }
                },
                resolve: {
                    /* @ngInject */
                    translatePartialLoader: function ($translatePartialLoader) {
                        $translatePartialLoader.addPart('componentes/recuperar');
                    }
                }
            })
            .state('recuperar/finish', {
                parent: 'enXogo',
                url: '/nueva-contrasena?key&email',
                data: {
                    authorities: ['ROLE_ANONYMOUS'],
                    pageTitle: 'recuperar.title'
                },
                views: {
                    'content@': {
                        template: "<recuperar-finish></recuperar-finish>"
                    }
                },
                resolve: {
                    /* @ngInject */
                    translatePartialLoader: function ($translatePartialLoader) {
                        $translatePartialLoader.addPart('componentes/recuperar');
                        $translatePartialLoader.addPart('admin');
                    }
                }
            })

            // === Estados que requieren que el usuario esté autenticado ===

            .state('enXogo/base', {
                parent: 'enXogo',
                redirectTo: 'enXogo/xogos',
                data: {
                    authorities: ['ROLE_USUARIO']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/components/aspg/enxogo/en-xogo.template.html'
                    }
                },
                resolve: {
                    /* @ngInject */
                    nombre: function(Principal){
                        return Principal.identity().then(function(identity){
                            return identity.nombre;
                        });
                    }
                }
            })
            .state('enXogo/xogos', {
                parent: 'enXogo/base',
                url: '/xogos',
                views: {
                    'contentXogos': {
                        template: '<xogos xogos="$resolve.xogos"></xogos>'
                    }
                },
                resolve: {
                    /* @ngInject */
                    xogos: function (Xogo) {
                        return Xogo.get().$promise;
                    },
                    /* @ngInject */
                    translatePartialLoader: function ($translatePartialLoader) {
                        $translatePartialLoader.addPart('componentes/managerLogin');
                    }
                }
            })
            .state('enXogo/perfil', {
                parent: 'enXogo/base',
                url: '/perfil',
                data: {
                    pageTitle: 'enXogo.perfil.titulo'
                },
                views: {
                    'contentXogos': {
                        template: '<h2 translate="enXogo.perfil.titulo"></h2>' +
                        '<registro mode="edit" usuario="$resolve.usuarioAspg"></registro>'
                    }
                },
                resolve: {
                    /* @ngInject */
                    usuarioAspg: function (UsuarioAspg) {
                        return UsuarioAspg.getAutenticado().$promise.then(function (data) {
                            return data;
                        });
                    },
                    /* @ngInject */
                    translatePartialLoader: function ($translatePartialLoader) {
                        $translatePartialLoader.addPart('componentes/registro');
                    }
                }
            })
            .state('enXogo/cambiar-contrasena', {
                parent: 'enXogo/base',
                url: '/cambiar-contrasena',
                data: {
                    pageTitle: 'enXogo.cambiarcontrasena.titulo'
                },
                views: {
                    'contentXogos': {
                        template: '<h2 translate="enXogo.cambiarcontrasena.titulo"></h2>' +
                        '<change-password></change-password>'
                    }
                },
                resolve: {
                    /* @ngInject */
                    usuarioAspg: function (UsuarioAspg) {
                        return UsuarioAspg.getAutenticado().$promise.then(function (data) {
                            return data;
                        });
                    },
                    /* @ngInject */
                    translatePartialLoader: function ($translatePartialLoader) {
                        $translatePartialLoader.addPart('componentes/changepassword');
                        $translatePartialLoader.addPart('admin');
                    }
                }
            })
            .state('enXogo/salir', {
                parent: 'enXogo/base',
                views: {
                    'contentXogos': {
                        controller: 'LogoutController'
                    }
                }
            });
    }
})();
