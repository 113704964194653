(function () {
    'use strict';

    /**
     * @ngdoc component
     * @name app.component:tooltip
     *
     * @param {String} label Texto a internacionalizar que se mostrará en el tooltip
     * @param {String} [position=right] Posición del tooltip. Valores: _top_, _top-left_, etc.
     *
     * @description
     * Referencia: {@link https://angular-ui.github.io/bootstrap/#tooltip}
     *
     */
    angular
        .module('app')
        .component('tooltip', {
            templateUrl: 'app/components/form/tooltip/tooltip.html',
            bindings: {
                label: '@',
                position: '@'
            },
            controllerAs: 'ctrl'
        });

})();
