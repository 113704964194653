(function () {
    'use strict';

    /**
     * @ngdoc component
     * @name app.component:filterInput
     * @description
     * Componente que incluye un input sobre las tablas para permitir el filtrado.
     *
     * @param {Object} filter Variable en la que se almacenará el texto del input
     *
     */
    angular
        .module('app')
        .component('filterInput', {
            templateUrl: 'app/components/form/filter/filter.input.html',
            bindings: {
                filter: '='
            },
            controllerAs: 'ctrl'
        });

})();
