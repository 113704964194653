(function () {
    'use strict';

    angular
        .module('app')
        .config(alertServiceConfig);

    /* @ngInject */
    function alertServiceConfig(AlertServiceProvider) {
        // set below to true to make alerts look like toast
        AlertServiceProvider.showAsToast(true);
    }

})();
