(function () {
    'use strict';

    angular.module('app')
        .factory('Administrador', Service);

    /* @ngInject */
    function Service(ResourceHelper) {
        var entidad = "administradores";
        var url = "api/" + entidad;
        return ResourceHelper.getResource(entidad, {}, {
            'getById': {
                method: 'GET',
                url: url + '/getById/:id'
            },
            'getUser': {
                method: 'GET',
                url: url + '/:login'
            },
            'findAll': {
                method: 'GET',
                url: url + '/findAll'
            },
            'changePassword': {
                method: 'POST',
                url: url + '/change_password'
            }
        });
    }
})();
