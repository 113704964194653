(function () {
    'use strict';

    angular
        .module('app')
        .component('suscriptoresListAdmin', {
            templateUrl: 'app/components/aspg/usuarios/admin/suscriptores/suscriptores-list.admin.html',
            controller: Controller,
            controllerAs: 'ctrl'
        });

    /* @ngInject */
    function Controller($stateParams, NgTableParams, NgTableHelper, ModalService, Suscriptor, NG_TABLE_DEFAULT_PARAMS) {
        var vm = this;

        vm.item = Suscriptor;
        vm.pagina = $stateParams.page ? $stateParams.page : 1;
        vm.filter = {
            key: null
        };
        vm.tableParams = new NgTableParams({
            count: NG_TABLE_DEFAULT_PARAMS.size,
            page: vm.pagina,
            filter: vm.filter,
            sorting: {email: 'asc'}
        }, NgTableHelper.settings(vm));

        // Eliminar
        vm.showRemoveConfirmation = function (suscriptor) {
            ModalService.open({
                templateUrl: 'app/components/form/delete/entity.delete.modal.html',
                controller: 'EntityDeleteModalController',
                controllerAs: 'ctrl',
                resolve: {
                    /* @ngInject */
                    item: function (Suscriptor) {
                        return new Suscriptor(suscriptor);
                    },
                    params: function () {
                        return {
                            title: 'usuarios.admin.suscriptores.delete.title',
                            body: 'usuarios.admin.suscriptores.delete.confirm',
                            property: 'email'
                        };
                    }
                }
            }
            ).result.then(function () {
                vm.tableParams.reload();
            });
        };

        vm.showEditModal = function (suscriptor) {
            ModalService.open({
                    templateUrl: 'app/components/aspg/usuarios/admin/suscriptores/suscriptor-edit.html',
                    controller: 'suscriptorEditConroller',
                    controllerAs: 'ctrl',
                    resolve: {
                        /* @ngInject */
                        item: function (Suscriptor) {
                            return new Suscriptor(suscriptor);
                        }
                    }
                }
            ).result.then(function () {
                vm.tableParams.reload();
            });
        };

    }

})();
