(function () {
    'use strict';

    angular.module('app')
        .factory('WebUtil', Service);

    /* @ngInject */
    function Service() {
        return {
            getRutaWebsBase: getRutaWebsBase,
            getRutaWebs: getRutaWebs,
            getRutaWebPublica: getRutaWebPublica
        };

        function getRutaWebsBase() {
            return '/upload/webs/';
        }

        function getRutaWebs(id) {
            return getRutaWebsBase() + id + '/';
        }

        function getRutaWebPublica() {
            return '/webs/';
        }
    }

})();
