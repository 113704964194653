(function () {
    'use strict';

    angular
        .module('app')
        .config(stateConfig);

    /* @ngInject */
    function stateConfig($stateProvider) {
        $stateProvider.state('login', {
            parent: 'admin',
            url: '/login',
            data: {
                authorities: [],
                pageTitle: 'login.title'
            },
            views: {
                'content@': {
                    template: '<login-admin></login-admin>'
                }
            },
            ncyBreadcrumb: {
                skip: true
            }
        });
    }
})();
