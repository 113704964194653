(function () {
    'use strict';

    /**
     * @ngdoc service
     * @name app.service:EnumService
     * @description
     * Operaciones para tratar con enumerados.
     *
     */
    angular.module('app')
        .factory('EnumService', EnumService);

    /* @ngInject */
    function EnumService($http, $q) {
        var cache = {};

        return {
            /**
             * @ngdoc function
             * @name get
             * @methodOf app.service:EnumService
             * @description
             * Esta operación cachea en la memoria del cliente los enumerados (si se recarga la página se pierden).
             * Todos las operaciones para obtener los enumerados deberían definirse en `EnumController.java`.
             *
             * @param {String} tipo Nombre de la operación definida en `EnumController`, que se corresponde con un enumerado. (Ej: rol-admin).
             * @returns {Array} Lista de enumerados obtenidos.
             */
            get: get
        };

        function get(tipo) {
            if (cache[tipo]) {
                var defered = $q.defer();
                var promise = defered.promise;
                defered.resolve(cache[tipo]);
                return promise;
            }

            // FIXME: Así sea cachea durante la sesión. Una vez se recarga la página la caché se pierde. Se puede también cachear la petición cambiando la URL base (lo que está en /api no se cachea)
            return $http.get('api/enum/' + tipo, {cache: true}).then(function (result) {
                cache[tipo] = result.data;
                return result.data;
            });
        }
    }
})();
