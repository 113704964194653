(function () {
    'use strict';

    /**
     * @ngdoc object
     * @name app.object:permisos_constants
     *
     * @description
     * Función para calcular si se tienen permisos para realizar una acción.
     *
     * Se incluye aquí (a parte de en el servicio) para que se pueda utilizar al definir los estados.
     *
     * @requires app.service:Permisos
     *
     */
    angular
        .module('app')
        // Nº de elementos por página en las tablas
        .constant('PERMISOS', function (actual, config) {
            if (!actual) {
                return true;
            }

            if (config === 'none') {
                return false;
            } else if (config === 'all') {
                return true;
            } else if (config === 'list') {
                return actual === 'list';
            } else if (config === 'edit') {
                return actual === 'list' || actual === 'edit';
            } else if (config === 'create') {
                return actual === 'list' || actual === 'edit' || actual === 'create';
            }
            return true;
        })
    ;
})();
