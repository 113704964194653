(function () {
    'use strict';

    angular
        .module('app')
        .component('categoriaNovedadListAdmin', {
            templateUrl: 'app/components/novedades/admin/categorianovedad/categoria-novedad-list.admin.html',
            controller: Controller,
            controllerAs: 'ctrl',
            bindings: {
                permisos: '<'
            }
        });

    /* @ngInject */
    function Controller($stateParams, NG_TABLE_DEFAULT_PARAMS, NgTableParams, NgTableHelper,
                        ModalService, CategoriaNovedad, Permisos) {
        var vm = this;

        vm.item = CategoriaNovedad;
        vm.pagina = $stateParams.page ? $stateParams.page : 1;
        vm.filter = {
            key: undefined
        };
        vm.tableParams = new NgTableParams({
            count: NG_TABLE_DEFAULT_PARAMS.size,
            page: vm.pagina,
            filter: vm.filter,
            sorting: {nombre: 'asc'}
        }, NgTableHelper.settings(vm));

        if (Permisos.tienePermisos('create', vm.permisos)) {
            // Eliminar
            vm.showRemoveConfirmation = function (id) {
                ModalService.open({
                        templateUrl: 'app/components/form/delete/entity.delete.modal.html',
                        controller: 'EntityDeleteModalController',
                        controllerAs: 'ctrl',
                        resolve: {
                            /* @ngInject */
                            item: function (CategoriaNovedad) {
                                return CategoriaNovedad.get({id: id}).$promise;
                            },
                            params: function () {
                                return {
                                    title: 'novedades.categoriaNovedad.admin.delete.title',
                                    body: 'novedades.categoriaNovedad.admin.delete.confirm',
                                    property: 'nombre'
                                };
                            }
                        }
                    }
                ).result.then(function () {
                    vm.tableParams.reload();
                });
            };
        }
    }

})();
