(function () {
    'use strict';

    angular
        .module('app')
        /* @ngInject */
        .constant('NIVEL_EDUCATIVO_FORM_ADMIN', function (modal) {
            var resolve = "$resolve.";
            if (modal) {
                resolve = "ctrl.resolve."
            }
            return {
                component: '<nivel-educativo-form-admin ' +
                'item="' + resolve + 'item" ' +
                'previous-params="' + resolve + 'previousParams"' +
                '></nivel-educativo-form-admin>',
                resolve: {
                    /* @ngInject */
                    item: function (NivelEducativo) {
                        return new NivelEducativo();
                    }
                }
            }
        });
})();
