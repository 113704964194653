(function () {
    'use strict';

    angular
        .module('app')
        .component('asociarseForm', {
            templateUrl: 'app/components/aspg/asociarseform/asociarse-form.html',
            controllerAs: 'ctrl',
            controller: Controller
        });

    /* @ngInject */
    function Controller($rootScope, $translate, Asociarse, Permisos, COMPONENTES, vcRecaptchaService) {
        var vm = this;
        vm.captcha = {
            resuelto: false,
            response: undefined
        };

        vm.mostrarCaptcha = Permisos.tienePermisos('all', COMPONENTES.contacto.captcha);

        function init() {
            vm.item = new Asociarse();
            vm.item.situacion = $translate.instant('asociarse.form.situacion.activo');
            vm.terminos = false;
        }

        vm.init = init;

        // Guardar formulario
        vm.save = function () {
            vm.item.captchaToken = vm.captcha.response;

            vm.item.$save().then(function () {
                init();
                vm.form.$setPristine();
                vcRecaptchaService.reload();
            }).catch(function (response) {
                $rootScope.mostrarErrores(response, vm.form);
            });
        };

        vm.captchaSuccess = function(response) {
            vm.captcha.resuelto = true;
            vm.captcha.response = response.response;
        };

        init();
    }

})();
