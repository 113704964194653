(function () {
    'use strict';

    angular
        .module('app')
        /* @ngInject */
        .constant('MATERIA_FORM_ADMIN', function (modal) {
            var resolve = "$resolve.";
            if (modal) {
                resolve = "ctrl.resolve."
            }
            return {
                component: '<materia-form-admin ' +
                'item="' + resolve + 'item" ' +
                'previous-params="' + resolve + 'previousParams"' +
                'niveles-educativos="' + resolve + 'nivelesEducativos" ' +
                '></materia-form-admin>',
                resolve: {
                    /* @ngInject */
                    item: function (Materia) {
                        return new Materia();
                    },
                    /* @ngInject */
                    nivelesEducativos: function (NivelEducativo) {
                        return NivelEducativo.query({sortProperty: 'orden'}).$promise.then(function (data) {
                            return data.content;
                        });
                    }
                }
            }
        });
})();
