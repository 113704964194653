(function () {
    'use strict';

    angular.module('app')
        .factory('NovedadUtil', Service);

    /* @ngInject */
    function Service() {
        return {
            getRuta: getRuta
        };

        function getRuta(id) {
            return '/upload/novedades/' + id + '/';
        }
    }

})();
