(function () {
    'use strict';

    angular.module('app')
        .factory('AulaDigitalUtil', Service);

    /* @ngInject */
    function Service() {
        return {
            getRutaRecursosBase: getRutaRecursosBase,
            getRutaRecursos: getRutaRecursos
        };

        function getRutaRecursosBase() {
            return '/upload/recursoseducativos/';
        }

        function getRutaRecursos(id) {
            return getRutaRecursosBase() + id + '/';
        }
    }

})();
