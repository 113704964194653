(function () {
    'use strict';

    angular
        .module('app')
        /* @ngInject */
        .constant('FEED_RSS_FORM_ADMIN', function (modal) {
            var resolve = "$resolve.";
            if (modal) {
                resolve = "ctrl.resolve."
            }
            return {
                component: '<feed-rss-form-admin ' +
                'item="' + resolve + 'item" ' +
                'previous-params="' + resolve + 'previousParams"' +
                'permisos-categoria="' + resolve + 'permisosCategoria"' +
                'categorias="' + resolve + 'categorias"' +
                '></feed-rss-form-admin>',
                resolve: {
                    /* @ngInject */
                    item: function (FeedRss) {
                        return new FeedRss();
                    },
                    /* @ngInject */
                    permisosCategoria: function (COMPONENTES) {
                        return COMPONENTES.feedsRss.categoriasAdmin;
                    },
                    /* @ngInject */
                    categorias: function (CategoriaRss) {
                        return CategoriaRss.query({sortProperty: 'nombre'}).$promise.then(function (data) {
                            return data.content;
                        });
                    }
                }
            }
        });
})();
