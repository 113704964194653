(function () {
    'use strict';

    /**
     * @ngdoc component
     * @name app.component:datePicker
     * @description
     * Componente para la selección de una fecha.
     *
     * Referencia: https://angular-ui.github.io/bootstrap/#datepicker
     *
     * @param {String} id Identificador del select
     * @param {String} label Mensaje de la etiqueta
     * @param {Object} model Campo que se modificará
     * @param {Boolean} [disabled=false] Campo deshabilitado
     * @param {Boolean} [required=false] Campo obligatorio
     * @param {Object} [options={datepickerMode: 'year', formatYear: 'yyyy', maxDate: new Date(), startingDay: 1, showWeeks: false}]
     * Opciones para configurar el calendario. Ej: `{datepickerMode: 'day', maxDate: ''}`
     * @param {Boolean} [hoy=false] Si es true, en caso de que model sea null y además, required, se pondrá a día de hoy (`new Date()`)
     * @param {String} [format=dd/MM/yyyy] Formato de la fecha
     * @param {Function} change Función que se llamará al cambiar el input
     * @param {Boolean} [showBar=true] Booleano para mostrar u ocultar la barra inferior del DatePicker
     * @param {Boolean} [readonly=false] Booleano para deshabilitar el input
     *
     * @requires app.component:labelForm
     * @requires app.service:DateUtils
     *
     */
    angular
        .module('app')
        .component('datePicker', {
            templateUrl: 'app/components/form/date/date-picker.html',
            bindings: {
                id: '@',
                label: '@',
                model: '=', // required
                disabled: '<',
                required: '<',
                options: '<',
                hoy: '<',
                format: '@',
                change: '<',
                showBar: '<?', // opcional
                readonly: '<?' // opcional
            },
            controller: Controller,
            controllerAs: 'ctrl'
        });

    /* @ngInject */
    function Controller(DateUtils) {
        var vm = this;

        function init() {
            // Si recibimos la fecha del servidor, convertimos segundos a milisegundos
            vm.model = DateUtils.convertDateTimeFromServer(vm.model);

            if (!vm.model && vm.required && vm.hoy) {
                vm.model = new Date();
            }
        }

        vm.opened = false;
        if (!vm.format) {
            vm.format = 'dd/MM/yyyy';
        }

        if (typeof(vm.showBar) === 'undefined') {
            vm.showBar = true;
        }

        if (typeof(vm.readonly) === 'undefined') {
            vm.readonly = false;
        }

        if (vm.options && vm.options.minDate === 'today') {
            vm.options.minDate = new Date();
        }

        vm.dateOptions = {
            datepickerMode: 'year',
            formatYear: 'yyyy',
            maxDate: new Date(),
            startingDay: 1,
            showWeeks: false
        };
        angular.merge(vm.dateOptions, vm.options);

        vm.openDatePicker = function () {
            vm.opened = true;
        };

        init();
    }

})();
