(function () {
    'use strict';

    angular
        .module('app')
        .config(stateConfig);

    /* @ngInject */
    function stateConfig($stateProvider) {
        $stateProvider
            .state('registro', {
                parent: 'public',
                url: '/registro',
                data: {
                    authorities: ['ROLE_ANONYMOUS'],
                    pageTitle: 'global.menu.registro'
                },
                views: {
                    'content@': {
                        template: '<h1 translate="registro.titulo"></h1>' +
                        '<registro mode="create"></registro>'
                    }
                },
                resolve: {
                    /* @ngInject */
                    translatePartialLoader: function ($translatePartialLoader) {
                        $translatePartialLoader.addPart('componentes/registro');
                    }
                }
            });
    }
})();
