(function () {
    'use strict';

    /**
     * @ngdoc component
     * @name app.component:imagen
     * @description
     * Componente para la subida de imágenes.
     *
     * @param {Object} model Imagen
     * @param {String} carpeta Ruta a la carpeta de la imagen.
     * @param {Number} indice Necesario si las imágenes van dentro de un bucle, por ejemplo.
     * @param {Object} modelRadio En caso de que se quiera incluir un radio input para seleccionar una imagen principal.
     * @param {Function} onUploadedImage Función de callback que se llama cuando se selecciona una imagen.
     * @param {Function} onRemoveImage Función de callback que se llama cuando se elimina una imagen.
     * @param {Boolean} [edicion=true] Poner a false para no permitir la edición.
     *
     * @requires app.component:loading
     */
    angular.module('app')
        .component('imagen', {
            templateUrl: "app/components/form/archivos/imagen/imagen.html",
            controllerAs: 'ctrl',
            controller: Controller,
            bindings: {
                model: "=",
                carpeta: "@",
                indice: "<",
                modelRadio: "<",
                onUploadedImage: '&',
                onRemoveImage: '&',
                edicion: "<"
            }
        });

    /* @ngInject */
    function Controller($timeout, Upload, AlertService) {

        var vm = this;

        activate();

        function activate() {
            vm.model = vm.model || {};

            if (!vm.model.rutaImagen) {
                if (vm.model.path) {
                    vm.model.rutaImagen = vm.carpeta + "n_" + vm.model.path;
                } else {
                    vm.model.rutaImagen = null;
                }
            }

            if (vm.edicion === null || vm.edicion === undefined) {
                vm.edicion = true;
            }
        }

        function onUploaded() {
            vm.onUploadedImage();
        }

        vm.isSubmitted = function (form) {
            while (!!form) {
                if (form.$submitted) return true;
                form = form.$$parentForm;
            }
            return false;
        };

        vm.eliminarImagen = function () {
            vm.model.eliminar = true;
            vm.model.rutaImagen = null;
            vm.model.path = null;
            vm.model.archivoTemporal = null;

            // Llamamos al callback para eliminar el elemento de la lista
            vm.onRemoveImage({imagen: vm.model});
        };

        function generateThumb(file) {
            if (file != null) {
                if (file.type.indexOf('image') > -1) {
                    $timeout(function () {
                        var fileReader = new FileReader();
                        fileReader.readAsDataURL(file);
                        fileReader.onload = function (e) {
                            $timeout(function () {
                                vm.model.rutaImagen = e.target.result;
                            });
                        }
                    });
                }
            }
        }

        vm.onImagenSelect = function (files) {
            vm.model.eliminar = false;
            if (files && files.length) {

                vm.model.path = null;
                vm.progresoImagen = true;

                // Previsualizamos imagen
                generateThumb(files[0]);

                // Subimos imagen
                Upload.upload({
                    url: "api/archivos-temporales",
                    data: {
                        file: files[0],
                        ext: "jpeg,png,gif,jpg"
                    },
                    method: 'POST'
                }).success(function (response) {
                    vm.progresoImagen = null;
                    vm.model.archivoTemporal = response.msg;
                    vm.model.path = files[0].name;
                    onUploaded();
                }).error(function (data) {
                    vm.progresoImagen = null;
                    vm.eliminarImagen();
                    if (data && data.msg === 'invalid extension') {
                        AlertService.error("error.invalidextension", {archivo: files[0].name});
                    } else {
                        AlertService.error("admin.form.error.fail");
                    }
                });
            }
        };

        vm.clickUpload = function () {
            document.getElementById('inputFoto' + vm.indice).click();
        };
    }

})();
