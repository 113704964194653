(function (angular) {
    'use strict';

    /**
     * @ngdoc service
     * @name app.service:Language
     * @description
     * Operaciones relacionadas con el idioma de la aplicación.
     *
     */
    angular.module('app')
        .factory('Language', factory);

    /* @ngInject */
    function factory($translate, LANGUAGES) {
        return {
            /**
             * @ngdoc function
             * @name getCurrent
             * @methodOf app.service:Language
             * @returns {String} Idioma actual de la aplicación.
             */
            getCurrent: getCurrent,
            /**
             * @ngdoc function
             * @name getDefault
             * @methodOf app.service:Language
             * @returns {String} Idioma por defecto.
             */
            getDefault: getDefault,
            /**
             * @ngdoc function
             * @name getList
             * @methodOf app.service:Language
             * @returns {Array} Lista de idiomas disponibles.
             */
            getList: getList
        };

        function getCurrent() {
            if ($translate.use()) return $translate.use();
            return $translate.preferredLanguage();
        }

        function getDefault() {
            return LANGUAGES.default;
        }

        function getList() {
            return LANGUAGES.list;
        }
    }
})(angular);
