(function () {
    'use strict';

    /**
     * @ngdoc component
     * @name app.component:changePassword
     * @description
     * Componente para modificar la contraseña del usuario autenticado.
     *
     */
    angular
        .module('app')
        .component('changePassword', {
            templateUrl: 'app/components/changepassword/change-password.html',
            controllerAs: 'ctrl',
            /* @ngInject */
            controller: function ($rootScope, $timeout, Auth) {
                var vm = this;

                $timeout(function () {
                    angular.element('.form-group:eq(0) input').focus();
                });

                vm.save = function () {
                    Auth.changePassword(vm.item).then(function () {
                        vm.confirmPassword = "";
                        vm.item = {};
                        vm.form.$setPristine();
                    }).catch(function (response) {
                        $rootScope.mostrarErrores(response, vm.form);
                    });
                };
            }

        });

})();
