(function () {
    'use strict';

    angular
        .module('app')
        .component('materiaListAdmin', {
            templateUrl: 'app/components/auladigital/admin/materia/materia-list.admin.html',
            controller: Controller,
            controllerAs: 'ctrl'
        });

    /* @ngInject */
    function Controller($stateParams, NgTableParams, NgTableHelper,
                        ModalService, Materia, NG_TABLE_DEFAULT_PARAMS) {
        var vm = this;

        vm.item = Materia;
        vm.pagina = $stateParams.page ? $stateParams.page : 1;
        vm.filter = {
            key: null,
            padreId: $stateParams.nivelid
        };
        vm.tableParams = new NgTableParams({
            count: NG_TABLE_DEFAULT_PARAMS.size,
            page: vm.pagina,
            filter: vm.filter,
            sorting: {orden: 'asc'}
        }, NgTableHelper.settings(vm));

        // Eliminar
        vm.showRemoveConfirmation = function (id) {
            ModalService.open({
                    templateUrl: 'app/components/form/delete/entity.delete.modal.html',
                    controller: 'EntityDeleteModalController',
                    controllerAs: 'ctrl',
                    resolve: {
                        /* @ngInject */
                        item: function (Materia) {
                            return Materia.get({id: id}).$promise;
                        },
                        params: function () {
                            return {
                                title: 'aulaDigital.materia.admin.delete.title',
                                body: 'aulaDigital.materia.admin.delete.confirm',
                                property: 'nombre'
                            };
                        }
                    }
                }
            ).result.then(function () {
                vm.tableParams.reload();
            });
        };
    }

})();
