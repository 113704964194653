(function () {
    'use strict';

    angular
        .module('app')
        .config(stateConfig);

    /* @ngInject */
    function stateConfig($stateProvider) {
        $stateProvider.state('exploradorArchivos', {
            url: '/explorador-archivos',
            data: {
                pageTitle: 'exploradorArchivos.title',
                authorities: ['ROLE_ADMIN']
            },
            views: {
                'filemanager@': {
                    template: '<explorador-archivos></explorador-archivos>'
                }
            },
            resolve: {
                /* @ngInject */
                authorize: function (Auth) {
                    return Auth.authorize();
                },
                /* @ngInject */
                isFilemanager: function ($rootScope) {
                    $rootScope.isFilemanager = true;
                }
            }
        });
    }
})();
