(function () {
    'use strict';

    angular
        .module('app')
        .config(stateConfig);

    /* @ngInject */
    function stateConfig($stateProvider, STATE_HELPER, NIVEL_EDUCATIVO_FORM_ADMIN, COMPONENTES, PERMISOS) {
        var permisos = COMPONENTES.aulaDigital.nivelesAdmin;

        var config = NIVEL_EDUCATIVO_FORM_ADMIN();
        var base = "admin/aulaDigital/nivelEducativo";
        var params = {
            base: base,
            view: 'contentChildren',
            baseUrl: "/" + base,
            translateBase: "aulaDigital.nivelEducativo.admin.",
            templateList: '<nivel-educativo-list-admin permisos="$resolve.permisos"></nivel-educativo-list-admin>',
            templateForm: config.component
        };

        var state = STATE_HELPER(params);
        // Estado Padre
        angular.merge(state.parent, {
            data: {
                authorities: ['ROLE_ADMIN'],
                hideBreadcrumb: true
            },
            views: {
                'content@': {
                    template: '<ng-include src="\'app/components/auladigital/admin/aula-digital-breadcrumb.admin.html\'"></ng-include>' +
                    '<div ui-view="contentChildren"></div>'
                }
            },
            resolve: {
                /* @ngInject */
                breadcrumb: function ($breadcrumb) {
                    return $breadcrumb;
                },
                /* @ngInject */
                translatePartialLoader: function ($translatePartialLoader) {
                    $translatePartialLoader.addPart('componentes/aulaDigital');
                }
            }
        });
        // Listar
        angular.merge(state.list, {
            resolve: {
                /* @ngInject */
                permisos: function () {
                    return permisos;
                }
            }
        });
        // Crear
        angular.merge(state.create, {
            resolve: config.resolve
        });
        // Editar
        angular.merge(state.edit, {
            resolve: angular.merge(config.resolve, {
                /* @ngInject */
                item: function (NivelEducativo, $stateParams) {
                    return NivelEducativo.get({id: $stateParams.id}).$promise;
                }
            })
        });

        // Se definen los estados

        if (PERMISOS('list', permisos)) {
            $stateProvider
                .state(params.base, state.parent)
                .state(state.list.name, state.list);
        }
        if (PERMISOS('create', permisos)) {
            $stateProvider
                .state(state.create.name, state.create);
        }
        if (PERMISOS('edit', permisos)) {
            $stateProvider
                .state(state.edit.name, state.edit);
        }
    }
})();
