(function (angular) {
    'use strict';

    angular
        .module('app')
        .component('infoTienda', {
            templateUrl: 'app/components/aspg/infotienda/info-tienda.html',
            controller: infoTiendaController,
            controllerAs: 'ctrl'
        });

    /* @ngInject */
    function infoTiendaController(InfoTienda) {
        var vm = this;

        InfoTienda.getPromocion().$promise.then(function (data) {
            if (data.id){
                vm.promocion = data;
                var hoy = new Date();
                hoy.setHours(0);
                hoy.setMinutes(0);
                hoy.setSeconds(0);
                hoy.setMilliseconds(0);
                vm.diasPromocion = Math.floor(((vm.promocion.data_fin_vixencia - hoy)/1000/60/60)/24);
            } else {
                vm.promocion = null;
                InfoTienda.getDestacados().$promise.then(function (data) {
                    vm.destacados = data;
                });
            }
        }).catch(function(){
            vm.destacados = [];
            vm.promocion = null;
        });
    }
})(angular);
