(function () {
    'use strict';

    angular.module('app')
        .factory('CategoriaWeb', Service);

    /* @ngInject */
    function Service(ResourceHelper) {
        var entidad = "categoria-web";
        return ResourceHelper.getResource(entidad, {}, {
            bajar: {
                method: 'GET',
                url: 'api/' + entidad + '/bajar',
                params: {
                    id: null
                }
            },
            subir: {
                method: 'GET',
                url: 'api/' + entidad + '/subir',
                params: {
                    id: null
                }
            }
        });
    }

})();
