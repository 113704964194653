(function () {
    'use strict';

    /**
     * @ngdoc object
     * @name app.object:admin_constants
     *
     * @description
     * Constantes para la parte de administración:
     *
     * - ADMIN_PRINCIPAL: `"admin/admin"`
     * - NG_TABLE_DEFAULT_PARAMS: `{size: 10}`
     *
     */
    angular.module('app')
        .constant('ADMIN_PRINCIPAL', "admin/novedad")
        // Nº de elementos por página en las tablas
        .constant('NG_TABLE_DEFAULT_PARAMS', {size: 25});
})();
