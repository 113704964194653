(function () {
    'use strict';

    /**
     * @ngdoc service
     * @name app.service:JsonUtils
     * @description
     * Servicio con operaciones comunes para tratar con JSONs.
     *
     */
    angular
        .module('app')
        .factory('JsonUtils', Service);

    /* @ngInject */
    function Service() {

        return {
            /**
             * @ngdoc function
             * @name cleanJson
             * @methodOf app.service:JsonUtils
             * @param {Object} item Objeto cuyas propiedades se van a eliminar
             * @returns {Object} Elimina todas las propiedades de un objeto.
             * El objeto resultante está vacío (`{}`), pero mantiene la referencia.
             */
            cleanJson: cleanJson
        };

        function cleanJson(item) {
            // for enumerable properties
            var props = Object.keys(item);
            for (var i = 0; i < props.length; i++) {
                delete item[props[i]];
            }
        }
    }

})();
