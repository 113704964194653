(function (angular) {
    'use strict';

    angular
        .module('app')
        .component('headerMenu', {
            templateUrl: 'app/components/aspg/headermenu/header-menu.html',
            controllerAs: 'ctrl'
        });
})(angular);
